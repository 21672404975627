import React from "react";
import styles from "./SidePanelModal.module.css";
import { CloseButton } from "react-bootstrap";

function SidePanelModal({ isOpen, onClose, children, setSelectedNode }) {
  const modalClassName = isOpen ? " open" : "";

  if (!isOpen) return null;

  return (
    <div
      className={styles["side-panel-modal-overlay"]}
      // style={{position:'fixed',left:'10%',zIndex:'999'}}
      onClick={onClose}
    >
      <div
        className={`${styles["side-panel-modal"]} ${
          modalClassName && styles["open"]
        }`}
      >
        <CloseButton
          style={{ float: "right", color: "#fff" }}
          variant="white"
          onClick={setSelectedNode(null)}
        />
        {children}
      </div>
    </div>
  );
}

export default SidePanelModal;
