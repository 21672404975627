/**
 * SearchModal component for displaying search results and executing commands.
 */
import CommandsMgr from "../canvas_commands";
import { CloudProvider } from "../canvas_commands";
import awsNodeTypes from "../../icons/aws-icons";
import React, { useState, useEffect, useRef } from "react";
import styles from "../view-edit-architecture.module.css";
import { CrossSvg } from "../../../../images/svg/icons";
import Copy from "../../../../images/copy.svg";
import Retry from "../../../../images/retry_img.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SearchModal = ({
  items,
  setItems,
  title = "",
  executeCommand,
  setSearchLoader,
  descriptionId,
  setSResponse,
  sResponse,
  socket,
  askAIbuttonClickHandler
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [startY, setStartY] = useState(0);
  let commandsMgr = new CommandsMgr(
    CloudProvider.AWS,
    descriptionId,
    setSResponse,
    sResponse,
    socket
  );
  const askAiResponseRef = useRef(null);

  useEffect(() => {
    // Define the function for handling keydown events
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        setHoveredIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
      } else if (event.key === "ArrowDown") {
        setHoveredIndex((prevState) =>
          prevState < items.length - 1 ? prevState + 1 : items.length - 1
        );
      }
    };

    // Add the event listener to the window
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup: remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const executeCommands = (commandsStr) => {
    // Split the commands string into an array of commands
    const commands = commandsStr.split("\n");
    console.log(commands);
    let newNodes = [],
      newNode = null;
    let newEdges = [],
      newEdge = null;
    // Loop over the commands and call executeCommand for each of them
    for (const command of commands) {
      [newNode, newEdge] = commandsMgr.execute(command, setSearchLoader, false);
      // if newNode is not null and newNode is not in awsNodeTypes
      if (newNode && !awsNodeTypes[newNode.type]) {
        console.log("newNode is not null and newNode is not in awsNodeTypes");
        console.log(newNode);
        continue;
      }
      if (newNode) {
        newNodes.push(newNode);
      }
      if (newEdge) {
        newEdges.push(newEdge);
      }
    }
    // Add the new nodes and edges to the graph
    commandsMgr.updateCanvas(newNodes, newEdges);
    setItems([]);
  };

  const handleCopyAskAIResponse = async (text) =>{
    try {
      await navigator.clipboard.writeText(text.summary + ": " + text.description);
    }catch (err) {
      console.error("Failed to copy", err);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    if (askAiResponseRef.current) {
      setInitialHeight(askAiResponseRef.current.getBoundingClientRect().height);
      setStartY(e.clientY);
   }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (askAiResponseRef.current) {
      const deltaY = startY - e.clientY;
      const newHeight = initialHeight + deltaY;
      askAiResponseRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
    <div className={styles['resize-handle']} onMouseDown={handleMouseDown}>
      <div
        className={styles['close-button']}
      >
        <span onClick={() => setItems([])} style={{position:'absolute'}}><CrossSvg/></span>
      </div>
    </div>
    <div className={styles["search-ask-ai-res-modal"]}>
      {items.length > 0 && (
        <div ref={askAiResponseRef} className={styles["resize-ask-ai-res-modal"]}>
          <div
            className={styles['search-modal-class-content']}
            style={{
              // marginLeft: "1rem",
              // marginTop: "0px",
              // marginRight: "1rem",
            }}
          >
            {items?.map((item, index) => (
              <div
                className="d-flex flex-row"
                style={{
                  marginBottom: "1.0rem",
                  // this line is added for the hover effect
                  // backgroundColor:
                  //   index === hoveredIndex ? "#e1e1e1" : "initial",
                }}
                key={index}
                // onClick={() => executeCommands(item.commands)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className={styles['search-modal-item']} style={{opacity: index === hoveredIndex ? 1 : 0.5,}}>
                  {capitalizeWords(item.summary)}: &nbsp;
                  <span className="">{item.description}</span>
                </div>
                <div className={styles['response-copy']} onClick={() => handleCopyAskAIResponse(item)}><img src = {Copy} alt="copy"/></div>
              </div>
            ))}
            <div className={styles["warning-message"]}>
              This response is generated by AI and may contain inaccuracies. 
              Please verify independently and consult a human expert for critical decisions.
            </div>
            <span className={styles["res-try-again"]}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip">Retry</Tooltip>}
              >
                <button onClick={askAIbuttonClickHandler}>
                  <img src={Retry} alt="retry_img" style={{width:'20px', filter:'invert(1)', opacity:'0.6'}}/>
                </button>
              </OverlayTrigger>
            </span>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export default SearchModal;
