import { useState, useRef } from 'react';

const useFormHandler = () => {

  // This is for the script running in preview@ account.
  const scriptURL = "https://script.google.com/macros/s/AKfycbwEqVL6ttm1te7ofrAiCYJ22TbotBeCIdKZqkIrd-TY4x5vmeZUaZBfA2F_sAAtA7Xi/exec"

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [message, setMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const setEmailHandler = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (!checkIsValidEmail(value)) {
      setIsValidEmail(false);
      setEmailError("*invalid email address");
    } else {
      setIsValidEmail(true);
      setEmailError("");
    }
  };

  const setNameHandler = (e) => {
    const value = e.target.value;
    setName(value);
  };

  const setAffiliationHandler = (e) => {
    const value = e.target.value;
    setAffiliation(value);
  };

  const setMessageHandler = (e) => {
    const value = e.target.value;
    setMessage(value);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    let requestBody = new FormData(formRef.current);

    fetch(scriptURL, { method: "POST", body: requestBody, mode: 'no-cors' })
      .then((response) => {
        setIsSubmitting(false);
        setEmail("");
        setShowAccessModal(false);
        setShowThanksModal(true);
      })
      .catch((error) => {
        setIsSubmitting(false);
        setIsValidEmail(false);
        setEmailError("*invalid email address");
      });
  };

  function checkIsValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return {
    email,
    name,
    affiliation,
    message,
    isValidEmail,
    emailError,
    showAccessModal,
    showThanksModal,
    isSubmitting,
    formRef,
    setEmailHandler,
    setNameHandler,
    setAffiliationHandler,
    setMessageHandler,
    formSubmitHandler,
    setEmail,
    setName,
    setAffiliation,
    setMessage,
    setIsValidEmail,
    setEmailError,
    setShowAccessModal,
    setShowThanksModal,
  };
};

export default useFormHandler;