import React from "react";
import { NewLogoSvg } from "../../../../images/svg";
import useFormHandler from '../../../NewLanding2/useFormHandler';
import AccessModal from '../../../NewLanding2/AccessModal';
import ThanksModal from '../../../NewLanding2/ThanksModal';

const Header = () => {

  const {
    email,
    name,
    affiliation,
    message,
    isValidEmail,
    emailError,
    showAccessModal,
    showThanksModal,
    isSubmitting,
    formRef,
    setEmailHandler,
    setNameHandler,
    setAffiliationHandler,
    setMessageHandler,
    formSubmitHandler,
    setEmail,
    setName,
    setAffiliation,
    setMessage,
    setIsValidEmail,
    setEmailError,
    setShowAccessModal,
    setShowThanksModal,
  } = useFormHandler();

  return (
    <div className="header-border">
      <div className="header-body">
        <a className={`navbar-brand`} href={`${window.location.origin}`}>
          <NewLogoSvg />
        </a>
        {/* <button className="join-btn">Join Beta</button> */}
        <button
            className="join-btn"
            type="button"
            onClick={() => {
              setEmail("");
              setName("");
              setAffiliation("");
              setMessage("");
              setIsValidEmail(null);
              setEmailError(false);
              setShowAccessModal(true);
            }}
          >
            Join Beta
        </button>
        <AccessModal
          show={showAccessModal}
          handleClose={() => setShowAccessModal(false)}
          formRef={formRef}
          name={name}
          affiliation={affiliation}
          message={message}
          email={email}
          isValidEmail={isValidEmail}
          emailError={emailError}
          isSubmitting={isSubmitting}
          setNameHandler={setNameHandler}
          setAffiliationHandler={setAffiliationHandler}
          setMessageHandler={setMessageHandler}
          setEmailHandler={setEmailHandler}
          formSubmitHandler={formSubmitHandler}
        />
      <ThanksModal
        show={showThanksModal}
        handleClose={() => setShowThanksModal(false)}
      />
      </div>
    </div>
  );
};

export default Header;
