// import React, { useState, useEffect } from "react";

// // Function to modify SVG content
// const modifySvgContent = (svgContent) => {
//   const parser = new DOMParser();
//   const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");

//   // Remove the <rect> with id="Rectangle"
//   const rectElement = svgDoc.querySelector('rect[id="Rectangle"]');
//   if (rectElement) {
//     rectElement.remove();
//   }

//   // Remove the <rect> with stroke="#879196"
//   const borderElement = svgDoc.querySelector('rect[stroke="#879196"]');
//   if (borderElement) {
//     borderElement.remove();
//   }

//   // Modify `fill` attribute of the <g> with fill="#FFFFFF"
//   const gElement = svgDoc.querySelector('g[fill="#FFFFFF"]');
//   if (gElement) {
//     gElement.setAttribute("fill", "#a3a3a3");
//   }

//   // Convert the updated SVG back to string
//   return new XMLSerializer().serializeToString(svgDoc);
// };

// // Component to load and modify SVG
// const SvgIcon = ({ src, alt }) => {
//   const [svgContent, setSvgContent] = useState(null);

//    useEffect(() => {
//     fetch(src)
//       .then((response) => response.text())
//       .then((data) => {
//         const  modifiedSvg = modifySvgContent(data);
//              setSvgContent(modifiedSvg);
//       })
//       .catch((error) => console.error("Error loading SVG: ", error));
//    }, []);

//   return <div dangerouslySetInnerHTML={{ __html: svgContent }} alt={alt} />;
// };

// // Define the paths to the SVGs
// const svgPaths = {
//   storage: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Storage_16.svg").default,
//   compute: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Compute_16.svg").default,
//   analytics: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Analytics_16.svg").default,
//   database: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Database_16.svg").default,
//   machinelearning: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Artificial-Intelligence_16.svg").default,
//   networking: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Networking-Content-Delivery_16.svg").default,
//   appinteg: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Application-Integration_16.svg").default,
//   bizapps: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Business-Applications_16.svg").default,
//   containers: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Containers_16.svg").default,
//   devtools: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Developer-Tools_16.svg").default,
//   frontend: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Front-End-Web-Mobile_16.svg").default,
//   iot: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Internet-of-Things_16.svg").default,
//   mgmtgov: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Management-Governance_16.svg").default,
//   media: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Media-Services_16.svg").default,
//   sec: require("../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Security-Identity-Compliance_16.svg").default,
// };

// // Usage of SvgIcon in the `icons` object
// export const icons = {
//   "Compute": <SvgIcon src={svgPaths.compute} alt="Compute" />,
//   "Storage": <SvgIcon src={svgPaths.storage} alt="Storage" />,
//   "Analytics": <SvgIcon src={svgPaths.analytics} alt="Analytics" />,
//   "Database": <SvgIcon src={svgPaths.database} alt="Database" />,
//   "Application Integration": <SvgIcon src={svgPaths.appinteg} alt="Application Integration" />,
//   "Networking & Content Delivery": <SvgIcon src={svgPaths.networking} alt="Networking & Content Delivery" />,
//   "Business Applications": <SvgIcon src={svgPaths.bizapps} alt="Business Applications" />,
//   "Containers": <SvgIcon src={svgPaths.containers} alt="Containers" />,
//   "Developer Tools": <SvgIcon src={svgPaths.devtools} alt="Developer Tools" />,
//   "Front-End Web & Mobile": <SvgIcon src={svgPaths.frontend} alt="Front-End Web & Mobile" />,
//   "Internet of Things": <SvgIcon src={svgPaths.iot} alt="Internet of Things" />,
//   "Machine Learning": <SvgIcon src={svgPaths.machinelearning} alt="Machine Learning" />,
//   "Management & Governance": <SvgIcon src={svgPaths.mgmtgov} alt="Management & Governance" />,
//   "Media Services": <SvgIcon src={svgPaths.media} alt="Media Services" />,
//   "Security, Identity, & Compliance": <SvgIcon src={svgPaths.sec} alt="Security, Identity, & Compliance" />,
// };


import React, { useEffect, useState } from 'react';
import storage from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Storage_16.svg";
import compute from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Compute_16.svg";
import analytics from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Analytics_16.svg";
import database from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Database_16.svg";
import machinelearning from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Artificial-Intelligence_16.svg";
import networking from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Networking-Content-Delivery_16.svg";
import appinteg from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Application-Integration_16.svg";
import bizapps from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Business-Applications_16.svg";
import containers from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Containers_16.svg";
import devtools from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Developer-Tools_16.svg";
import frontend from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Front-End-Web-Mobile_16.svg";
import iot from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Internet-of-Things_16.svg";
import mgmtgov from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Management-Governance_16.svg";
import media from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Media-Services_16.svg";
import sec from "../../../images/aws/Category-Icons/Arch-Category_16/Arch-Category_Security-Identity-Compliance_16.svg";

const rawIcons = {
  "Compute": compute,
  "Storage": storage,
  "Analytics": analytics,
  "Database": database,
  "Application Integration": appinteg,
  "Networking & Content Delivery": networking,
  "Business Applications": bizapps,
  "Containers": containers,
  "Developer Tools": devtools,
  "Front-End Web & Mobile": frontend,
  "Internet of Things": iot,
  "Machine Learning": machinelearning,
  "Management & Governance": mgmtgov,
  "Media Services": media,
  "Security, Identity, & Compliance": sec,
};

const CategoriesIcon = ({ setIcons }) => {
  const [processedIcons, setProcessedIcons] = useState([]);

  useEffect(() => {
    const modifySVGs = async () => {
      const batchSize = 1;
      const iconEntries = Object.entries(rawIcons);

      const processBatch = async (batch) => {
        const iconPromises = batch.map(async ([category, svgUrl]) => {
          try {
            // Fetch the imported SVG file directly
            const response = await fetch(svgUrl);
            const svgText = await response.text();

            // Parse the SVG text
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgText, "image/svg+xml");

            // Remove the <rect> with id="Rectangle"
            const rectElement = svgDoc.querySelector('rect[id="Rectangle"]');
            if (rectElement) rectElement.remove();

            // Remove the <rect> with stroke="#879196"
            const borderElement = svgDoc.querySelector('rect[stroke="#879196"]');
            if (borderElement) borderElement.remove();

            // Modify `fill` attribute of the <g> with fill="#FFFFFF"
            const gElement = svgDoc.querySelector('g[fill="#FFFFFF"]');
            if (gElement) gElement.setAttribute("fill", "#a3a3a3");

            // Convert the updated SVG back to string
            const modifiedSVG = new XMLSerializer().serializeToString(svgDoc);

            // Return modified SVG as an image element
            return {
              category,
              element: <img src={`data:image/svg+xml;utf8,${encodeURIComponent(modifiedSVG)}`} alt={category} />,
            };
          } catch (error) {
            console.error(`Error fetching or modifying SVG for ${category}:`, error);
            // Return null if fetching fails
            return null;
          }
        });

        return await Promise.all(iconPromises);
      };

      let batchResults = [];
      for (let i = 0; i < iconEntries.length; i += batchSize) {
        const batch = iconEntries.slice(i, i + batchSize);
        const batchIcons = await processBatch(batch);
        batchResults = [...batchResults, ...batchIcons.filter(icon => icon !== null)];
      }

      setProcessedIcons(batchResults);
    };

    modifySVGs();
  }, []);

  useEffect(() => {
    if (processedIcons.length > 0) {
      const iconObject = processedIcons.reduce((acc, { category, element }) => {
        acc[category] = element;
        return acc;
      }, {});

      setIcons(iconObject);
    }
  }, [processedIcons, setIcons]);

  return null;
};

export default CategoriesIcon;
