import Header from '../components/header';
import cloudx_award from '../../../../assets/cloudX.png';
import RightSidebar from './rightSidebar';
import "../components/details.css";
import Footer from '../../../NewLanding2/Footer';
import './inPress.css';

const InPress = () => {
  return (
    <div>
      <Header />
      <div className="press-blog-index-container">
        <div className="left-column">
          <span className="news-title">Press Release</span>
          <div className='resources-container'>
            <div className="press-blog-image-container">
              <a href="https://www.developerweek.com/cloudx/awards/#winners" target="_blank" rel="noopener noreferrer">
                <img src={cloudx_award} alt="cloudx_award" />
              </a>
            </div>
            <div className="title-description">
              <span className="resource-title">
                JigsawML Exits Stealth with AI-Powered Platform to Revolutionize Cloud Operations
              </span>
              <div style={{fontStyle:'italic', color:'black', paddingTop:'20px', textAlign: 'center'}}>
                <strong>
                  Wins Award for Cloud Management at the DeveloperWeek CloudX 2024 Conference
                </strong>
              </div>
              <div className="resource-description" style={{paddingTop:'20px', textAlign: 'justify' }}>
                <p className="equal-words"><strong>Boston, Massachusetts. November 13th, 2024 – </strong> JigsawML has exited stealth with its revolutionary new AI-powered Cloud Intelligence Platform that offers unparalleled visibility and support to teams managing the complexities of cloud infrastructure. JigsawML also announced that it has been named the 2024 winner of the CloudX Award for Cloud Management, presented at the DeveloperWeek CloudX 2024 Conference in Santa Clara on November 5th, 2024.</p>
                <p className="equal-words">Designed to streamline onboarding, optimize cloud costs, enhance security, and accelerate troubleshooting, JigsawML addresses the everyday challenges that cloud practitioners face. What sets JigsawML apart is its advanced engine, which understands the unique context in which enterprises operate within the cloud, enabling a more intuitive and tailored cloud experience. This contextual understanding allows JigsawML to provide more holistic support to cloud operations than was ever possible.</p>
                <p className="equal-words">"Many cloud tools claim to simplify cloud management but often leave teams struggling to adapt the tool to their unique context," said Pracheer Gupta, CEO of JigsawML. "JigsawML’s ability to understand how enterprises use the cloud goes beyond surface-level functionality, delivering relevant insights that cut through silos across cloud teams and provide a unified view of the entire cloud ecosystem."</p>
                <blockquote className="quote">
                  <br/>
                  JigsawML’s ability to understand how enterprises use the cloud goes beyond surface-level functionality, delivering relevant insights that cut through silos across cloud teams and provide a unified view of the entire cloud ecosystem.
                  <cite>— Pracheer Gupta, CEO of JigsawML</cite>
                </blockquote>
                <p className="equal-words">JigsawML’s uniqueness lies in its ability to automatically generate an interactive visualization of the enterprise’s entire cloud software architecture. This allows cloud teams to understand how their applications are interconnected and built on the cloud infrastructure. The platform provides a clear overview of an organization’s cloud assets and assists in solving the most pressing cloud-related challenges. Furthermore, JigsawML’s AI-driven engine allows users to ask questions about their cloud environment, delivering instant, actionable answers for smarter cloud optimization.</p>
                <p className="equal-words">Traditional cloud management tools often focus on limited functions, forcing cloud teams to segregate into isolated roles, such as FinOps, DevOps, application developers, platform engineers, and more - to tackle issues related to the cloud. This fragmented approach is ineffective, as most problems require a comprehensive understanding of the cloud environment. JigsawML addresses this challenge by offering a unified platform that connects all cloud practitioners, providing teams with an integrated and holistic view of their cloud.</p>
                <p className="equal-words">JigsawML is now available to enterprises and service providers who help enterprises enhance their cloud operations.</p>
                <span className="resource-title-small">About JigsawML</span>
                <p className="equal-words">JigsawML is an innovative technology company building deep tech solutions at the intersection of Cloud and AI. Dual-headquartered in Boston, Massachusetts, and San Francisco, California, JigsawML is committed to transforming how enterprises manage and optimize their cloud infrastructure through advanced AI-driven insights and a unified platform that simplifies cloud complexity.</p>
                <p className="equal-words">JigsawML’s bold vision is to make the world of cloud computing <strong style={{fontStyle:'italic'}}> just a bit less complicated </strong> for enterprises worldwide.</p>
                <p className="equal-words">
                    For more information about JigsawML and its revolutionary cloud intelligence platform, visit <a href="https://www.jigsawml.com" target="_blank" rel="noopener noreferrer"> www.jigsawml.com</a>.
                </p>
                <span className="resource-title-small">About CloudX 2024</span>
                <p className="equal-words">CloudX 2024, the premier international cloud computing conference, will gather over 3,500 tech professionals in Santa Clara, California from November 5-7, 2024. Attendees will explore the latest cloud innovations, network with industry leaders and discover cutting-edge solutions in this rapidly evolving field.</p>
                <p className="equal-words">
                  For more information about CloudX, visit <a href="https://www.developerweek.com/cloudx/" target="_blank" rel="noopener noreferrer">https://www.developerweek.com/cloudx/</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="right-sidebar">
          <RightSidebar />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InPress;
