import React from 'react';
import ModalUI from "../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./landing2.module.css";

const AccessModal = ({
  show,
  handleClose,
  formRef,
  name,
  affiliation,
  message,
  email,
  isValidEmail,
  emailError,
  isSubmitting,
  setNameHandler,
  setAffiliationHandler,
  setMessageHandler,
  setEmailHandler,
  formSubmitHandler,
}) => (
  <ModalUI
    show={show}
    handleClose={handleClose}
    modalTitle={""}
    theme="light"
    classes="border-no"
    size="md"
  >
    <Row>
      <Col>
        <div className={`px-4 ${styles["request-access-container"]}`}>
          <h3 className="text-black m-auto mb-2 text-center">
            Sign up for the beta access!
          </h3>
          <p className="text-black opacity-50 m-auto mb-0 text-center">
            Ready to make your cloud experience seamless?
          </p>
          <form ref={formRef} onSubmit={formSubmitHandler}>
            <div className={`${styles["request-access-group"]}`}>
              <Form.Group className={`${styles["request-access-input"]}`}>
                <Form.Control
                  value={name}
                  name="Name"
                  onChange={setNameHandler}
                  placeholder="👤 Your Full Name"
                  className={`${styles["custom-input"]}`}
                  required
                />
                <Form.Control
                  type="email"
                  value={email}
                  name="Email"
                  onChange={setEmailHandler}
                  placeholder="✉️ Email Address"
                  className={`${styles["custom-input"]} ${
                    isValidEmail === false && styles["error"]
                  }`}
                  required
                />
                <Form.Control
                  value={affiliation}
                  name="Affiliation"
                  onChange={setAffiliationHandler}
                  placeholder="💼 Company Affiliation and Role"
                  className={`${styles["custom-input"]}`}
                  required
                />
                <Form.Control
                    as="textarea"
                    value={message}
                    name="Message"
                    onChange={setMessageHandler}
                    placeholder="🏷️ What interests you about trying the Beta version of JigsawML? (Optional)"
                    className={`${styles["custom-input"]}`}
                    rows={3}
                  />
                <p className={styles["error-text"]}>
                  {emailError !== "" && emailError}
                </p>
              </Form.Group>
              <button
                className={`${styles["btn"]} ${styles["request-access-btn"]}`}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "⏳" : "Request access"}
              </button>
            </div>
          </form>
        </div>
      </Col>
    </Row>
  </ModalUI>
);

export default React.memo(AccessModal);