import Dropdown from "react-bootstrap/Dropdown";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import "./InfraStructure.css";
import downArrow from "../../../images/downVector.svg";
import searchImg from "../../../images/searchIcons.svg";
import vector from "../../../images/Vector.svg";
import file from "../../../images/file.svg";
import drag from "../../../images/drag.svg";
import azIcon from "../../../images/AZIcon.svg";
import arrow_right from "../../../images/arrow-right.svg"
import checkSvg from "../../../images/check.svg";
import { useState, useEffect, useContext, useRef } from "react";
import crossImg from "../../../images/clear.svg"
import Vpcs from "./Vpcs";
import AvailabilityZone from "./AvailabilityZone";
import Regions, { removeDuplicates } from "./Regions";
import UniversalResource from "./UniversalResource";
import CreateContext from "../../../store/create-context";
import ResourceIconPopUp from "./component/resourceIcons/ResourceIconPopUp";
import { resourceIcon } from "./Regions";
import { fetchSubnetData, getAllCategories, getSubnetData, sliceSubArr } from "./Subnet";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import WebSocketInstance from "../../../common/WebSocketInstance";
import { InfraServicesModal } from "./InfraServicesModal";
import { InfraCostsModal } from "./InfraCostsModal";
import CategoriesIcon from "./CategoriesIcon";


const InfraStructure = ({infraViewResponse}) => {
  const [awsAccounts, setAwsAccounts] = useState([]);
  const [regions, setRegions] = useState([]);
  const [availZones, setAvailZones] = useState([]);
  const [vpcs, setVpcs] = useState([]);
  const [subnetCategories, setSubnetCategories] = useState([]);
  const [processedCategories, setProcessedCategories] = useState(false);
  const [openedDropDowns, setIsOpenedDropDowns] = useState({
    Account: false,
    Regions: false,
  });
  const [globalData, setGlobalData] = useState({});
  const [selectedDropDownValues, setSelectedDropDownValues] = useState({
    "aws:account": "",
    "aws:region": "",
  });
  const [hideGlobal, setHideGlobal] = useState(true);
  const [searchBar, setSearchBar] = useState("");
  const {selectedInfraviewDetail, setSelectedInfraviewDetail} = useContext(CreateContext);
  const [projectId, setProjectId] = useState(sessionStorage.getItem("jml_workspace_id"));
  const [selectedResource, setSelectedResource] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [target, setTarget] = useState();
  const [showPopUp, setShowPopUp] = useState();
  const [openCards, setOpenCards] = useState({});
  const [hideAz, setHideAz] = useState(true);
  const [allOpen, setAllOpen] = useState(false);
  const [maxCategories, setMaxCategories] = useState(0);
  const azRef = useRef([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [infraServices, setInfraServices] = useState([]);
  const [infraCosts, setInfraCosts] = useState([]);
  const [icons, setIcons] = useState({});
  const [openAccordionForItem, setOpenAccordionForItem] = useState("");
  const socket = WebSocketInstance.getInstance();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const max = Math.max(
      ...availZones.map((az) => Object.keys(az.children.categories).length)
    );
    setMaxCategories(max);
  }, [availZones]);

  useEffect(() => {
    let userAlreadySelectedItems =  selectedInfraviewDetail.find((item) => item.projectId === projectId);
    const awsAccountArr = infraViewResponse.nodes.filter(
      (el) => el.type === "aws:account"
    );
    setAwsAccounts(awsAccountArr);
    handleAccountChange(JSON.stringify(awsAccountArr[0]));
    if(userAlreadySelectedItems){
      const copyElement = {...userAlreadySelectedItems};
      setRegions(copyElement.regions);
      setGlobalData(copyElement.globalData);
      setAvailZones(copyElement.availZones);
      setVpcs(copyElement.vpcs);
      setSelectedInfraviewDetail((prev) => {
          return prev;
      });
      if(copyElement){
          delete copyElement.projectId;
          setSelectedDropDownValues((prev) => ({...prev, ...copyElement}));
      }
    }else if (awsAccountArr.length > 0) {
      setSelectedDropDownValues((prev) => ({
        ...prev,
        [awsAccountArr[0].type]: awsAccountArr[0],
      }));
      const findawsRegionArr = infraViewResponse.nodes.filter(
        (el) =>
          el.type === "aws:region" &&
          ((Array.isArray(el.parent) &&
            el.parent.includes(awsAccountArr[0].id)) ||
            el.parent === awsAccountArr[0].id)
      );
      if (findawsRegionArr.length > 0) {
        const regionArr = addChildrens(findawsRegionArr);
        regionArr.sort(
          (a, b) => b.children.child.length - a.children.child.length
        );
        setRegions(regionArr);
        setSelectedDropDownValues((prev) => ({
          ...prev,
          [regionArr[0].type]: regionArr[0],
        }));
        handleRegionChange(JSON.stringify(regionArr[0]));
      }
    }

    return () => {
      socket.removeEventListener("get_infra_services", getInfraServices);
      socket.removeEventListener("get_infra_costs", getInfraCosts);
    }
  }, []);

  useEffect(() => {
    let newElement = true;
    setSelectedInfraviewDetail((prev) => {
      const updatedDetails = prev.map((el) => {
        if (el.projectId === projectId) {
          newElement = false;
          return {
            ...el,
            ...selectedDropDownValues,
            regions,
            globalData,
            availZones,
            vpcs,
          };
        }
        return el;
      });
      if (newElement) {
        return [
          ...updatedDetails,
          {
            ...selectedDropDownValues,
            projectId,
            regions,
            globalData,
            availZones,
            vpcs,
          },
        ];
      }
      return updatedDetails;
    });
  }, [
    selectedDropDownValues,
    awsAccounts,
    projectId,
    regions,
    globalData,
    availZones,
    vpcs,
  ]);

  const getInfraServices = (event) => {
    if (event.success) {
      setInfraServices({
        supported_services: event.supported_services || [],
        unsupported_services: event.unsupported_services || []
      });
      setLoading(false);
    } else {
      console.error('Failed to retrieve infrastructure services:', event.error);
      setLoading(false);
    }
  }

  const getInfraCosts = (event) => {
    if (event.success) {
      setInfraCosts({
        cost_metrics: event.cost_metrics || []
      });
      setLoading(false);
    } else {
      console.error('Failed to retrieve infrastructure costs:', event.error);
      setLoading(false);
    }
  }
  const handleServiceCoverage = () => {
    setModalType('services');
    setInfraServices({})
    setLoading(true);
    socket.send("get_infra_services", {
      account_id: selectedDropDownValues["aws:account"]?.id,
      account_region: selectedDropDownValues["aws:region"]?.id,
      project_id: projectId
    });
    socket.addEventListener("get_infra_services", getInfraServices);
    setModalShow(true);
  };

  const handleServiceCosts = () => {
    setModalType('costs');
    setInfraCosts({})
    setLoading(true);
    socket.send("get_infra_costs", {
      account_id: selectedDropDownValues["aws:account"]?.id,
      account_region: selectedDropDownValues["aws:region"]?.id,
      project_id: projectId
    });
    socket.addEventListener("get_infra_costs", getInfraCosts);
    setModalShow(true);
  };

  const handleAccountChange = (e) => {
    clickOnDropDown("Account");
    setIsOpenedDropDowns((prev) => ({ ...prev, Account: !prev["Account"] }));
    let jsonElement = JSON.parse(e);
    setSelectedDropDownValues((prev) => ({
      ...prev,
      [jsonElement.type]: jsonElement,
    }));
    const findglobal = infraViewResponse.nodes.find(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:global"
    );
    addGlobalChildrens(findglobal);
    setGlobalData(findglobal);

    const findChilds = infraViewResponse.nodes.filter(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:region"
    );

    if (findChilds.length > 0) {
      const regionArr = addChildrens(findChilds);
      regionArr.sort(
        (a, b) => b.children.child.length - a.children.child.length
      );
      setRegions(regionArr);
      handleRegionChange(JSON.stringify(regionArr[0]));
    } else {
      resetResources();
    }
  };

  const handleRegionChange = (e) => {
    let jsonElement = JSON.parse(e);
    setSelectedDropDownValues((prev) => ({
      ...prev,
      [jsonElement.type]: jsonElement,
    }));
    const findChildsAz = infraViewResponse.nodes.filter(
      (element) =>
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "aws:az"
    );
    const findChildsVPC = infraViewResponse.nodes.filter((element) => {
      return (
        ((Array.isArray(element.parent) &&
          element.parent.includes(jsonElement.id)) ||
          element.parent === jsonElement.id) &&
        element.type === "ec2:vpc"
      );
    });
    const azArr = findChildsAz && addChildrens(findChildsAz);
    const vpcArr = findChildsVPC && addChildrens(findChildsVPC);
    setAvailZones(azArr);
    setVpcs(vpcArr);
  };

  const addChildrens = (parentArr) => {
    const childrens = parentArr.map((element) => {
      const child = infraViewResponse.nodes.filter(
        (el) =>
          ((Array.isArray(el.parent) && el.parent.includes(element.id)) ||
            el.parent === element.id) &&
          el.type !== "aws:region" &&
          el.type !== "aws:az" &&
          el.type !== "ec2:vpc" &&
          el.type !== "ec2:subnet"
      );
      const categories = getAllCategories(child)
      element.children = { child, categories};
      return element;
    });
    return childrens;
  };

   const addGlobalChildrens = (globalElement) => {
    const child = infraViewResponse.nodes.filter(
      (el) =>
        ((Array.isArray(el.parent) && el.parent.includes(globalElement?.id)) ||
          el.parent === globalElement?.id) &&
        (el.type === "iam:role" ||
          el.type === "iam:user" ||
          el.type === "iam:policy")
    );
    const categories = getAllCategories(child)
    if (child.length !== 0) {
      globalElement.children = { child, categories};
      return globalElement;
    }
  };

  const clickOnDropDown = (clickedOn) => {
    setIsOpenedDropDowns((prev) => {
      return { ...prev, [clickedOn]: !prev[clickedOn] };
    });
  };

  const resetResources = () => {
    setRegions([]);
    setAvailZones([]);
    setVpcs([]);
    setSelectedDropDownValues((prev) => ({ ...prev, "aws:region": "" }));
  };

  const toggleUniversalResource = () =>{
    setHideGlobal(!hideGlobal);
  }

  const handleResourceIconClick = (e, resource) => {
    e.stopPropagation();
    if(selectedResource && resource.id === selectedResource.id) {
        setShowPopUp(false);
        setSelectedResource("");
    }else{
        setSelectedResource(resource);
        setShowPopUp(true);
        setTarget(e.target);
        setAdditionalInfo(null);
    }
  }

  const handleSearchResultClick = (e, resource) =>{
    e.stopPropagation();
    if(openAccordionForItem && resource.id === openAccordionForItem.id){
      setOpenAccordionForItem("");
      setShowPopUp(false);
    }else{
      setShowPopUp(true);
      setTarget(e.target);
      setAdditionalInfo(null);
      setOpenAccordionForItem(resource);
    }
  }

  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>{
      setOpenCards((prev) => {
        const newState = { ...prev };
        newState[eventKey] = !prev[eventKey];
        return newState;
      });
    });
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
        className="card-toggle-btn"
      >
        {children}
      </button>
    );
  }

  const highlightText = (text, search) => {
    if (!search) return text;
    const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedSearch})`, "gi"));
    return parts.map((part, index) =>(
      part.toLowerCase() === search.toLowerCase() ? (
        <span key={index} className="highlighted-text">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      ))
    );
  };
  const filteredVpcs = vpcs?.filter(vpc =>
    vpc.name.toLowerCase().includes(searchBar.toLowerCase())
  ) || [];

  const filteredAvailableZone = availZones?.filter(az =>
    az.name.toLowerCase().includes(searchBar.toLowerCase())
  ) || [];

  const filterCategories = [];
  const filterType1 = [];
  const filterType2 = [];

  function processCategories(categories) {
    const newFilterCategories = [];
    const newFilterType1 = [];
    const newFilterType2 = [];
    Object.keys(categories).forEach((category) => {
      const categoryItems = categories[category];
      Object.keys(categoryItems).forEach((el) => {
        const nestedCategory = categoryItems[el];
        if (nestedCategory && nestedCategory.type && nestedCategory.type.includes(':')) {
          const [type1, type2] = nestedCategory.type.split(':');
          const categoryName = nestedCategory.name;
          filterCategories.push({ name: categoryName, type: nestedCategory.type, nestedCategory });
          filterType1.push({ name: type1 , nestedCategory});
          filterType2.push({ name: type2 , nestedCategory});
          newFilterCategories.push({ name: categoryName, type: nestedCategory.type, nestedCategory });
          newFilterType1.push({ name: type1 , nestedCategory});
          newFilterType2.push({ name: type2 , nestedCategory});
        }
      });
    });
    return {
      newFilterCategories,
      newFilterType1,
      newFilterType2
    };
  }

  const categories = selectedDropDownValues["aws:region"]?.children?.categories;
  if (categories) processCategories(categories);

  if (Array.isArray(availZones)) {
    availZones.forEach((az) => {
      if (az?.children?.categories) {
        processCategories(az.children.categories);
      }
    });
  }

  if (Array.isArray(vpcs)) {
    vpcs.forEach((vpc) => {
      if (vpc?.children?.categories) {
        processCategories(vpc.children.categories);
      }
    });
  }

  if(globalData?.children?.categories) {
    processCategories(globalData.children.categories)
  }
  const filterSubnets = (vpcs || [])
  .map(vpc => {
    const subnets = fetchSubnetData(vpc.id, availZones, infraViewResponse) || [];
    return subnets
      .map(subnet => sliceSubArr(subnet) || [])
      .reduce((acc, arr) => acc.concat(arr), []);
    })
  .reduce((acc, arr) => acc.concat(arr), [])
  .filter(sub => sub.name.toLowerCase().includes(searchBar.toLowerCase()));

  const filteredRegionResults = [
    ...filterCategories,
    ...filterType1,
    ...filterType2,
  ];

  const searchResults = filteredRegionResults.filter(item =>
    item.name.toLowerCase().includes(searchBar.toLowerCase())
  );

  const searchSubnetResults = subnetCategories
  ?.reduce((acc, category) => {
    const categoryValues = Object.values(category);
    categoryValues.forEach(arr => acc.push(...arr));
    return acc;
  }, [])
  ?.filter((item) =>
    item?.name?.toLowerCase().includes(searchBar?.toLowerCase())
  );

  const filteredResults = [
    ...searchResults,
    ...searchSubnetResults,
    ...filteredVpcs,
    ...filteredAvailableZone,
    ...filterSubnets
  ];

  const toggleAllCards = () => {
    setAllOpen(prev => !prev);
  }

  const handleClickAz = (index) => {
    setHideAz((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  }

  const handleMouseDown = (index, e) => {
    e.preventDefault();
    const onMouseMove = (event) => handleMouseMove(index, event);
    const onMouseUp = () => handleMouseUp(onMouseMove, onMouseUp);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const handleMouseMove = (index, e) => {
    if (azRef.current[index]) {
      const newWidth = e.clientX - azRef.current[index].getBoundingClientRect().left;
      azRef.current[index].style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = (onMouseMove, onMouseUp) => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const handleAccordionToggle = (children, toggleParentCard, toggleChildCard, toggleGrandchildCard) => {
    if (openAccordionForItem) {
      Object.keys(children.categories).forEach((category) => {
        const uniqueItems = removeDuplicates(children.categories[category]);
        Object.keys(uniqueItems).forEach((el) => {
          if (openAccordionForItem.type?.includes(":") && openAccordionForItem.type.split(":")[0] === el) {
            toggleParentCard(category);
            Object.keys(uniqueItems[el]).forEach((element) => {
              if (openAccordionForItem.type?.includes(":") && openAccordionForItem.type.split(":")[1] === element) {
                toggleChildCard(el);
              }
              uniqueItems[el][element].forEach((item) => {
                if (openAccordionForItem.name === item.name) {
                  toggleGrandchildCard(element);
                }
              })
            });
          }
        });
      });
    }
  };

  const isTrue = window.location.host.split(".")[0] === "scanner";

  return (
    <div className="infrastructure-main">
      {modalShow && modalType === 'services' && (
        <InfraServicesModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          infraServices={infraServices}
          loading={loading}
          selectedDropDownValues={selectedDropDownValues}
        />
      )}

      {modalShow && modalType === 'costs' && (
        <InfraCostsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          infraCosts={infraCosts}
          loading={loading}
          selectedDropDownValues={selectedDropDownValues}
        />
      )}
      <div>
        <div className="first-div">
          <span>
            <span className="Infra-text">Infrastructure View</span>
              <div className="Infra-links">
                <div onClick={handleServiceCoverage} className="Infra-services">Coverage</div>
                <span className="Infra-separator"> & </span>
                <div onClick={handleServiceCosts} className="Infra-costs">Costs</div>
              </div>
          </span>
          <div style={{ display: "flex", gap: "10px" }}>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="tooltip-right">
                  Universal Resource
                </Tooltip>
              }
            >
            <div className="vector-img" onClick={toggleUniversalResource}>
              <img src={vector} alt="vector" />
            </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="tooltip-right">
                  Open/Close all categories
                </Tooltip>
              }
            >
              <div className="vector-img" onClick={toggleAllCards}>
                <img src={file} alt="file" />
              </div>
            </OverlayTrigger>
            <div style={{ display: "flex", gap: "10px" }}>
              <Dropdown
                onSelect={(e) => handleAccountChange(e)}
                onClick={() => clickOnDropDown("Account")}
                autoClose = "inside"
              >
                <div className="account-label">Account</div>
                <Dropdown.Toggle variant="dark" className="dropdown-account">
                  <span className="acc-reg-name">
                    {selectedDropDownValues["aws:account"]?.name}
                  </span>
                  <img src={downArrow} alt="" className={`${!openedDropDowns["Account"] ? "p-2" : "uparrow p-2"}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className='menu-list'>
                  {awsAccounts.map((el) => (
                    <Dropdown.Item
                      key={el.id}
                      eventKey={JSON.stringify(el)}
                      style={{
                        background: `${
                          selectedDropDownValues[el.type]?.id === el.id
                            ? "#5A46FF"
                            : ""
                        }`,
                      }}
                    >
                      {el.name}
                      <span>
                        {selectedDropDownValues[el.type]?.id === el.id && (
                          <img src={checkSvg} alt="" />
                        )}
                      </span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown
                onSelect={(e) => handleRegionChange(e)}
                onClick={() => clickOnDropDown("Regions")}
                autoClose = "inside"
              >
                <div className="account-label">Region</div>
                <Dropdown.Toggle variant="dark" className="dropdown-account">
                  <span className="acc-reg-name">
                    {selectedDropDownValues["aws:region"]
                      ? selectedDropDownValues["aws:region"].name
                      : "Regions"}
                  </span>
                  <img src={downArrow} alt="" className={`${!openedDropDowns["Regions"] ? "p-2" : "uparrow p-2"}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu variant="dark" className='menu-list'>
                  {regions.map((region) => (
                    <Dropdown.Item
                      key={region.id}
                      eventKey={JSON.stringify(region)}
                      style={{
                        background: `${
                          selectedDropDownValues[region.type]?.id === region.id
                            ? "#5A46FF"
                            : ""
                        }`,
                      }}
                    >
                      <div className="region-list">
                        <span>{region.name}</span>
                        <span className="region-count">{region.children.child.length}</span>
                        <span>
                          {selectedDropDownValues[region.type]?.id ===region.id
                           && <img src={checkSvg} alt="" />}
                        </span>
                      </div>
                      <div className="resources-length">
                        Resources: {region.children.child.length}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <span className="search-box">
              <img className="search-img" src={searchImg} alt="" />
              <input
                type="search"
                placeholder="Search"
                className="search-bar"
                onChange={(e) => (setSearchBar(e.target.value))}
              />
              {searchBar &&
              <>
                <img className="clear-img" src={crossImg} alt="clear" onClick={() => setSearchBar("")}/>
                <div className="search-results">
                  {filteredResults.map((item, idx) => (
                    <div key={idx}
                      className="search-result-item"
                      onClick={(e) => handleSearchResultClick(e, item.nestedCategory ? item.nestedCategory : item)}
                    >
                      <span>{resourceIcon(item.type?.includes(":") ? item.type.split(":")[1] : item.name)}</span>
                      <span>{highlightText(item.name, searchBar)}</span>
                    </div>
                  ))}
                </div>
              </>}
            </span>
          </div>
        </div>
        <div className="infra-resources">
          <div className="resources-items">
            <CategoriesIcon setIcons={setIcons} />
            <div className="region-global-detail">
              <UniversalResource globalData={globalData} hideGlobal={hideGlobal}
                searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                CustomToggle={CustomToggle}  highlightText={highlightText} allOpen={allOpen}
                icons={icons} handleAccordionToggle={handleAccordionToggle}
                openAccordionForItem={openAccordionForItem}
              />
              <Regions selectedDropDownValues={selectedDropDownValues}
                searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                CustomToggle={CustomToggle} highlightText={highlightText} allOpen={allOpen}
                icons={icons} openAccordionForItem={openAccordionForItem}
                handleAccordionToggle={handleAccordionToggle}
              />
            </div>
            <div className="azs-vpcs-detail">
              <div className="azs-details">
                {availZones.map((az, index) => (
                  <div style={{ position: "relative" }} key={index}>
                  {!hideAz[index] ?
                  <>
                  <img src={drag} alt="drag" className={isTrue ? "drag-img-1" : "drag-img"} onClick={() => handleClickAz(index)} />
                  <div className="universal-res" ref={el => azRef.current[index] = el}>
                    <AvailabilityZone
                      azname={az.name} index={index}
                      availZones={availZones} azChild={az.children}
                      searchBar={searchBar} handleResourceIconClick={handleResourceIconClick}
                      CustomToggle={CustomToggle} maxCategories={maxCategories}
                      highlightText={highlightText} allOpen={allOpen} handleAccordionToggle={handleAccordionToggle}
                      icons={icons} openAccordionForItem={openAccordionForItem}
                    />
                    <div className="vpcs-data">
                      { vpcs.map((vpc) => {
                        const subnet = getSubnetData(vpc.id, az.id, infraViewResponse);
                        if (Array.isArray(subnet) && !processedCategories) {
                          const newProcessedCategories = [];
                          subnet.forEach((sub) => {
                            const sub1 = processCategories(sub.children.categories);
                            newProcessedCategories.push(sub1);
                          });
                          setSubnetCategories(newProcessedCategories);
                          setProcessedCategories(true);
                        }
                        return subnet.length > 0 ? (
                          <div key={vpc.id} className="vpcs-parent">
                          <Vpcs
                            subnet={subnet}
                            vpc={vpc}searchBar={searchBar}
                            handleResourceIconClick={handleResourceIconClick}
                            CustomToggle={CustomToggle} openCards={openCards} allOpen={allOpen}
                            highlightText={highlightText} setOpenCards={setOpenCards}
                            icons={icons} openAccordionForItem={openAccordionForItem}
                          />
                          </div>
                        ) : (
                          <div className="vpcs-parent" key={vpc.id}>
                            <div className="zero-subnets">
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="resize-handle" onMouseDown={(e) => handleMouseDown(index, e)}></div>
                  </div>
                  </>
                  :
                  <div className="hide-data">
                  <img src={arrow_right} alt="drag" className={isTrue ? "right-arrow-img1" : "right-arrow-img"} onClick={() => handleClickAz(index)} />
                  <div style={{paddingTop:'10px', paddingLeft: isTrue ? "14px" : "0"}}><img src={azIcon} alt="vector" /></div>
                  <span className="hide-data-name">{az.name}</span>
                </div>}
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopUp && <ResourceIconPopUp
       target={target}
       showPopUp={showPopUp}
       setShowPopUp={setShowPopUp}
       selectedResource={selectedResource}
       setSelectedResource={setSelectedResource}
       setOpenAccordionForItem={setOpenAccordionForItem}
       openAccordionForItem={openAccordionForItem}
       additionalInfo={additionalInfo}
       setAdditionalInfo={setAdditionalInfo}
       selectedDropDownValues={selectedDropDownValues}
      />}
    </div>
  );
};

export default InfraStructure;

