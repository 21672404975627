import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import "./InfraCostsModal.css";
import { infraviewResourceIcon } from "../new-icons/infraviewResource-icons";
import generic_application from "../../../images/aws/Resource-Icons/Res_General-Icons/Res_48_Dark/Res_Generic-Application_48_Dark.svg";
import { Spinner } from 'react-bootstrap';

export const InfraCostsModal = (props) => {
  const { show, onHide, infraCosts, loading, selectedDropDownValues } = props;
  const { cost_metrics = [] } = infraCosts || {};

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='res-header'>
          Service Costs for {selectedDropDownValues["aws:account"]?.id} in {selectedDropDownValues["aws:region"]?.name}
        </Modal.Header>
        <Modal.Body className='res-body'>
          {loading ? (
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Spinner  animation="grow" size="sm" />
            <span style={{ marginLeft: "5px" }}>Request being processed...</span>
          </div>
          ) : (
            cost_metrics.length > 0 ? (
              <table className="cost-metrics-table">
                <thead>
                  <tr>
                    <th>Service description</th>
                    <th>Month to date</th>
                    <th>Previous month</th>
                  </tr>
                </thead>
                <tbody>
                  {cost_metrics.map((item, index) => {
                    const serviceName = Object.keys(item)[0];
                    const costs = item[serviceName];
                    return (
                      <tr key={index}>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{serviceName}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                          {costs[0].toLocaleString('en-US', { maximumFractionDigits: 20 })}
                        </td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                          {costs[1].toLocaleString('en-US', { maximumFractionDigits: 20 })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p>Looks like you're not incurring any costs right now. For the most reliable information, please take a moment to review your AWS Billing and Cost Management dashboard.</p>
            )
          )}
        </Modal.Body>
        <Modal.Footer className='res-footer'>
          <div className='services-message'>
            The cost data provided above is sourced from AWS Cost Explorer. Please ensure that this feature is enabled for your AWS account. The figures represent unblended costs and only include services where the cost exceeds $0 for the month-to-date or the previous month. Always refer to your AWS Billing and Cost Management dashboard for the most accurate and up-to-date information.
          </div>
          <Button className='footer-button' onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
