import { useState } from "react";
import crossImg from "../../images/crossBtn.svg";
import menuImg from "../../images/menu.svg";
import styles from "./navbar.module.css";
import { NewLogoSvg } from "../../images/svg";
import { Link } from "react-router-dom";

const Navbar = ({
  setEmail,
  setName,
  setAffiliation,
  setMessage,
  setEmailError,
  setShowAccessModal,
  setIsValidEmail,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container-fluid">
      <nav className="navbar navbar-expand-sm py-4">
        <div className="container-fluid">
          <a
            className={`navbar-brand ${styles["navbar-brand-wrapper"]}`}
            href={`${window.location.origin}`}
          >
            <NewLogoSvg />
          </a>

          {/* Menu Toggle (Hamburger Icon) */}
          <div className={styles["menu-toggle"]}>
            <img
              src={menuImg}
              alt="menu"
              className={styles["menu-icon"]}
              onClick={toggleMenu}
            />
          </div>

          {/* Menu Content */}
          {isMenuOpen && (
            <div
              className={`${styles["menu-content"]} ${
                isMenuOpen ? styles["show"] : ""
              }`}
            >
              <div className={styles["menu-close"]} onClick={toggleMenu}>
                <img
                  src={crossImg}
                  alt="crossBtn"
                  className={styles["cross-icon"]}
                />
              </div>
              <div className={styles["nav-items"]}>
                <div className={styles["nav-item"]}>
                  <Link to="/product" className={styles["nav-link"]}>
                    Product
                  </Link>
                  <span className={styles["beta-badge"]} style={{marginLeft:'-70px'}}>Beta</span>
                </div>
                <Link to="/blogs" className={styles["nav-link"]}>
                  Blogs
                </Link>
                <Link to="/in-press" className={styles["nav-link"]}>
                  Press
                </Link>
              </div>
            </div>
          )}

          {/* Desktop View Nav Links */}
          <div className={styles["nav-middle-container"]}>
            <div className={styles["nav-item"]}>
              <Link to="/product" className={styles["nav-link"]}>
                Product<span className={styles["beta-badge"]}>Beta</span>
              </Link>
            </div>
            <Link to="/blogs" className={styles["nav-link"]}>
              Blogs
            </Link>
            <Link to="/in-press" className={styles["nav-link"]}>
              Press
            </Link>
          </div>

          {/* <a href="https://www.developerweek.com/cloudx/awards/#winners" target="_blank" rel="noopener noreferrer">
            <img src={cloudxBanner} alt="CloudX Winner Banner" className={styles.banner_image_class} />
          </a> */}

          {/* <span className="" style={{ fontSize: '18px', fontFamily: 'Work Sans, sans-serif', marginRight: '75px'}}> */}
          {/* <br/> */}
          {/* <button
              className={`${styles["btn-small"]}`}
              type="button"
              onClick={handleButtonClick}
            >
              Product Details
            </button> */}

          {/* Sign-up Button */}
          <button
            className={`${styles["btn"]}`}
            type="button"
            onClick={() => {
              setEmail("");
              setName("");
              setAffiliation("");
              setMessage("");
              setIsValidEmail(null);
              setEmailError(false);
              setShowAccessModal(true);
            }}
          >
            Sign up for Beta
          </button>
          {/* </span> */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
