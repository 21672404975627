import React from 'react';
import ModalUI from "../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./landing2.module.css";

const ThanksModal = ({ show, handleClose }) => (
  <ModalUI
    show={show}
    handleClose={handleClose}
    modalTitle={""}
    theme="light"
    classes="border-no"
    size="lg"
  >
    <Row>
      <Col className="text-center text-black">
        <h1 className={`fw-bold m-auto `} style={{ maxWidth: "24ch" }}>
          Thank you for your interest in joining our beta program!
        </h1>
        <p className={`opacity-50 mt-4`}>
          We'll be in touch soon to discuss the next steps and guide you through the onboarding process.
        </p>
        <button
          className={`m-auto mt-4 ${styles["btn"]} ${styles["btn-close"]}`}
          type="submit"
          onClick={handleClose}
        >
          Close
        </button>
      </Col>
    </Row>
  </ModalUI>
);

export default React.memo(ThanksModal);