import { Link } from "react-router-dom";
import { useEffect } from "react";
import styles from "./footer.module.css";
import jigsawml_logo from "../../images/JigsawML-10.svg";
import {
  NewLogoSvg,
} from "../../images/svg";

const Footer = () => {
  const isDisabled = false; // Set to false if /in-press and /blogs links should be enabled

  // Scroll to top when the component is mounted or the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means it runs on mount

  return (
    <div className={styles["footer-container"]}>
      <div className={`container-fluid ${styles["footer-section"]}`}>
        <div className={styles["footer-column"]}>
          {/* <img className={styles["footer-logo"]} src={jigsawml_logo} alt="Jigsawml logo" /> */}
          <NewLogoSvg />
          <p className={styles["footer-text"]}>
            Your AI-powered<br /> Cloud Intelligence Platform
          </p>
          <p className={styles["footer-copyright"]}>&#169; JigsawML 2024</p>
        </div>
        <div className={`${styles["footer-column"]} ${styles["footer-links"]}`}>
          <p className={styles["footer-heading"]}>Useful links</p>
          <Link
            to="/product"
            className={styles["footer-link"]}
            style={{ cursor: 'pointer' }} // Default cursor
          >
            Product
          </Link>
          <Link
            to={isDisabled ? "#" : "/blogs"}
            className={styles["footer-link"]}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            onClick={(e) => isDisabled && e.preventDefault()} // Prevent click if disabled
          >
            Blogs
          </Link>
          <Link
            to={isDisabled ? "#" : "/in-press"}
            className={`${styles["footer-link"]} ${isDisabled ? styles["disabled"] : ""}`}
            style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
            onClick={(e) => isDisabled && e.preventDefault()} // Prevent click if disabled
          >
            In the press
          </Link>
        </div>
        <div className={styles["footer-column"]}>
          <p className={styles["footer-heading"]}>Write to us</p>
          <a href="mailto:info@jigsawml.com" className={styles["footer-email"]}>info@jigsawml.com</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
