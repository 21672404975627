import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import drag from "../../../images/drag.svg";
import arrow_right from "../../../images/arrow-right.svg"
import vector from "../../../images/Vector.svg";
import downArrow from "../../../images/downVector.svg";
import "./InfraStructure.css";
import {removeDuplicates, resourceIcon} from "./Regions";
import { useEffect, useRef, useState } from "react";

const UniversalResource = ({ globalData, hideGlobal,
    searchBar,
    handleResourceIconClick,
    CustomToggle,
    highlightText,
    allOpen,
    icons,
    handleAccordionToggle,
    openAccordionForItem
    }) => {
  const [hideglobal, setHideglobal] = useState(true);
  const [openParentCards, setOpenParentCards] = useState({});
  const [openChildCards, setOpenChildCards] = useState({});
  const [openGrandchildCards, setOpenGrandchildCards] = useState({});
  const globalRef = useRef(null);


  const toggleParentCard = (index) => {
    setOpenParentCards((prev) => {
      const newState = { ...prev };
      newState[index] = allOpen || !prev[index];
      return newState;
    });
  };

  const toggleChildCard = (id) => {
    setOpenChildCards((prev) => {
      const newState2 = { ...prev };
      newState2[id] = allOpen || !prev[id];
      return newState2;
    });
  };

  const toggleGrandchildCard = (idx) => {
    setOpenGrandchildCards((prev) => {
      const newState3 = { ...prev };
      newState3[idx] = allOpen || !prev[idx];
      return newState3;
    });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (globalRef.current) {
      const newWidth = e.clientX - globalRef.current.getBoundingClientRect().left;
      globalRef.current.style.width = `${newWidth}px`;
    }

  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if(globalData?.id){
      handleAccordionToggle(globalData?.children, toggleParentCard, toggleChildCard, toggleGrandchildCard);
    }
  }, [openAccordionForItem]);

  const isTrue = window.location.host.split(".")[0] === "scanner";

  return (
    <>
      {hideGlobal && globalData?.id && (
        <div style={{ position: "relative"}}>
          {hideglobal ?
          <>
          <img src={drag} alt="drag" className={isTrue ? "drag-img-1" : "drag-img"} onClick={() => setHideglobal(!hideglobal)}/>
          <div className="universal-res" ref={globalRef}>
            <div className="resize-handle"></div>
            <span className="uni-reg-resource">
              <img src={vector} alt="vector" />
              <span>{highlightText(globalData?.name, searchBar)}</span>
            </span>
            <Accordion>
              {Object.keys(globalData?.children?.categories).map(
                (category, index) => {
                  const uniqueItems = removeDuplicates(globalData?.children?.categories[category]);
                  return (
                    <div key={category}>
                    <Card className="all-category-data">
                      <CustomToggle eventKey={index}>
                        <Card.Header className="compute-header" onClick={() => toggleParentCard(index)}>
                          <span className="compute-btn">
                            <span>{icons[category]}</span>
                            <span>{category}</span>
                          </span>
                          <span className="data-count">
                            <span className="category-count">
                              {Object.keys(uniqueItems).length}
                            </span>
                            <img
                              src={downArrow}
                              alt=""
                              className = {allOpen
                                ? (!openParentCards[index] ? 'uparrow' : '')
                                : (openParentCards[index] ? 'uparrow' : '')}
                            />
                          </span>
                        </Card.Header>
                      </CustomToggle>
                      <Accordion.Collapse
                       eventKey={index}
                       in={allOpen ? !openParentCards[index] : openParentCards[index]}
                      >
                        <Card.Body className="card-body">
                          <Accordion>
                              {Object.keys(uniqueItems).map((el, idx) => {
                                return (
                                  <Card className="compute-details" key={idx}>
                                    <CustomToggle eventKey={idx}>
                                      <Card.Header className="compute-details-header" onClick={() => toggleChildCard(el)}>
                                        <span className="compute-btn">
                                          {resourceIcon(el)}
                                          <span className="body-data">
                                            {highlightText(el, searchBar)}
                                          </span>
                                        </span>
                                        <span className="data-count">
                                          <span className="category-count">
                                            {Object.keys(uniqueItems[el]).length}
                                          </span>
                                          <img
                                            src={downArrow}
                                            alt=""
                                            className = {allOpen
                                              ? (!openChildCards[el] ? "arrow-up" : "vector-arrow")
                                              : (openChildCards[el] ? "arrow-up" : "vector-arrow")}
                                          />
                                        </span>
                                      </Card.Header>
                                    </CustomToggle>
                                    <Accordion.Collapse
                                     eventKey={idx}
                                     in={allOpen ? !openChildCards[el] : openChildCards[el]}
                                    >
                                      <Card.Body className="compute-details-body">
                                        <Accordion>
                                        {Object.keys(uniqueItems[el]).map((element, innerIdx) => (
                                          <Card
                                            className="compute-details"
                                            key={innerIdx}
                                          >
                                            <CustomToggle eventKey={innerIdx}>
                                              <Card.Header className="compute-details-header" onClick={() => toggleGrandchildCard(element)}>
                                                <span className="compute-btn">
                                                 {resourceIcon(element)}
                                                  <span className="body-data">
                                                  {highlightText(element, searchBar)}
                                                  </span>
                                                </span>
                                                <span className="data-count">
                                                  <span className="category-count">
                                                    {uniqueItems[el][element].length}
                                                  </span>
                                                  <img src={downArrow} alt=""
                                                    className = {allOpen
                                                      ? (!openGrandchildCards[element] ? "arrow-up" : "vector-arrow")
                                                      : (openGrandchildCards[element] ? "arrow-up" : "vector-arrow")}
                                                  />
                                                </span>
                                              </Card.Header>
                                            </CustomToggle>
                                            <Accordion.Collapse
                                             eventKey={innerIdx}
                                             in={allOpen ? !openGrandchildCards[element] : openGrandchildCards[element]}
                                            >
                                              <Card.Body className="compute-details-body">
                                              {uniqueItems[el][element].map((item, itemIdx) => (
                                                <div key={itemIdx} className="instances"
                                                  onClick={(e) => handleResourceIconClick(e, item)}
                                                >
                                                  <span>{resourceIcon(item.type?.includes(":") &&
                                                    item.type.split(":")[1])}</span>
                                                  <span className="body-data">{highlightText(item.name.length>10
                                                    ? item.name.slice(0,10)+"..." : item.name, searchBar)}
                                                  </span>
                                                </div>
                                              ))}
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>))}
                                        </Accordion>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                );
                              }
                            )}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    </div>
                  );
                }
              )}
            </Accordion>
            <div className="resize-handle" onMouseDown={handleMouseDown}></div>
          </div>
          </>
          :
          <div className="hide-data">
            <img src={arrow_right} alt="drag" className={isTrue ? "right-arrow-img1" : "right-arrow-img"} onClick={() => setHideglobal(!hideglobal)} />
            <div style={{paddingTop:'10px', paddingLeft: isTrue ? "15px" : "0"}}><img src={vector} alt="vector" /></div>
            <span className="hide-data-name">Universal resources</span>
          </div>}
        </div>
      )}
    </>
  );
};

export default UniversalResource;
