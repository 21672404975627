import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import context from "../../../store/create-context";
import Toastify from "../../../common/components/Toastify/Toastify";

function AdminRegisterPage({ onLogin, redirectTo }) {
  const [adminUsername, setAdminUsername] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const [command, setCommand] = useState("");

  const [uEmail, setUEmail] = useState("");
  const [uPassword, setUPassword] = useState("");
  const [uFirstName, setUFirstName] = useState("");
  const [uLastName, setULastName] = useState("");

  // State for password change and deletion
  const [resetEmail, setResetEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [deleteEmail, setDeleteEmail] = useState("");
  const [confirmDeleteEmail, setConfirmDeleteEmail] = useState("");

  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToastr, setShowToastr } = useContext(context);

  async function handleRegister() {
    if (!uEmail || !uPassword || !uFirstName) {
      setShowToastr({ show: true, type: "error", text: "All user fields are required for registration." });
      return;
    }
    setCommand("registeruser");
  }

  async function handleReset() {
    if (!resetEmail || !newPassword) {
      setShowToastr({ show: true, type: "error", text: "Email and new password are required for reset." });
      return;
    }
    setUEmail(resetEmail);
    setUPassword(newPassword);
    setCommand("resetpassword");
  }

  function handleDelete() {
    if (deleteEmail === confirmDeleteEmail) {
      setUEmail(deleteEmail);
      setCommand("deleteuser");
    } else {
      setShowToastr({ show: true, type: "error", text: "Email addresses do not match." });
    }
  }

  useEffect(() => {
    if (command) {
      handleSubmit();
    }
  }, [command]);

  async function handleSubmit() {
    if (!adminUsername || !adminPassword) {
      setShowToastr({ show: true, type: "error", text: "Admin credentials are required." });
      return;
    }

    let isAuthenticated = false;
    isAuthenticated = await onLogin(adminUsername, adminPassword, command, uEmail, uPassword, uFirstName, uLastName);

    if (isAuthenticated) {
      navigate(state?.path || redirectTo || "/login");
    } else {
      setShowToastr({ show: true, type: "error", text: "Authentication failed." });
    }
  }

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5"
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    width: "300px",
    gap: "10px"
  };

  const inputStyle = {
    padding: "10px",
    fontSize: "18px",
    borderRadius: "5px",
    border: "1px solid #ddd"
  };

  const buttonStyle = {
    padding: "10px",
    fontSize: "18px",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007BFF",
    cursor: "pointer"
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={e => e.preventDefault()} style={formStyle}>
        <input
          type="text"
          value={adminUsername}
          onChange={e => setAdminUsername(e.target.value)}
          placeholder="Admin Username"
          style={inputStyle}
        />
        <input
          type="password"
          value={adminPassword}
          onChange={e => setAdminPassword(e.target.value)}
          placeholder="Admin Password"
          style={inputStyle}
        />

        {/* User registration form */}
        <br/>
        Enter User Details
        <input
            type="text"
            value={uEmail}
            onChange={e => setUEmail(e.target.value)}
            placeholder="Email"
            style={inputStyle}
        />
        <input
            type="password"
            value={uPassword}
            onChange={e => setUPassword(e.target.value)}
            placeholder="Password"
            style={inputStyle}
        />
        <input
            type="text"
            value={uFirstName}
            onChange={e => setUFirstName(e.target.value)}
            placeholder="First Name"
            style={inputStyle}
        />
        <input
            type="text"
            value={uLastName}
            onChange={e => setULastName(e.target.value)}
            placeholder="Last Name"
            style={inputStyle}
        />
        <button type="button" onClick={handleRegister} style={buttonStyle}>Create User</button>

        <br/> <br/> <br/> <br/>
        {/* Password reset form */}
        <input
            type="text"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
            placeholder="Email of user"
            style={inputStyle}
        />
        <input
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            placeholder="New Password"
            style={inputStyle}
        />
        <button type="button" onClick={handleReset} style={buttonStyle}>Reset Password</button>

        {/* Delete User Form */}
        <input
            type="text"
            value={deleteEmail}
            onChange={e => setDeleteEmail(e.target.value)}
            placeholder="Email to Delete"
            style={{ ...inputStyle, userSelect: "none" }}
            onCopy={e => e.preventDefault()}
        />
        <input
            type="text"
            value={confirmDeleteEmail}
            onChange={e => setConfirmDeleteEmail(e.target.value)}
            placeholder="Confirm Email"
            style={inputStyle}
            onCopy={e => e.preventDefault()}
        />
        <button type="button" onClick={handleDelete} style={buttonStyle}>Delete User</button>
      </form>
      <Toastify
        show={showToastr["show"]}
        type={showToastr["type"]}
        message={showToastr["text"]}
        handleClose={() => {
          setShowToastr((props) => {
            return { ...props, show: false };
          });
        }}
      />
    </div>
  );
}

export default AdminRegisterPage;