import { Link } from 'react-router-dom';
import feed_icon from '../../../../assets/feed_icon.png';

import "../components/details.css";

const RightSidebar = () => {
    const imageTitle = [
        {
            src: feed_icon,
            alt: 'server_img',
            text: 'Blog: A New Era in Cloud Management Begins',
            link: '/blogs'
        },
        {
            src: feed_icon,
            alt: 'server_img',
            text: 'Press Release: JigsawML Unveils Award-Winning Cloud Intelligence Platform',
            link: '/in-press'
        }
    ];

    return (
        <div>
            <div className='news-text' style={{textAlign:'left', marginLeft: '10px' }}>Feed</div>
            <div>
                {imageTitle.map((item, index) => (
                    <div key={index} className='title-img' style={{textAlign:'left', marginLeft: '10px' }}>
                        <span><img src={item.src} alt={item.alt} /></span>
                        <Link to={item.link} style={{textDecoration:'none'}}>
                            <span>{item.text}</span>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RightSidebar;
