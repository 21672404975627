import React, { useEffect, useState, useContext } from "react";
import ModalUI from "../../../UI/ModalUI/ModalUI";
import styles from "./AddMultipleGitRepoModal.module.css";
import { Row, Col } from "react-bootstrap";
import cut from '../../../../../images/wrong.svg';
import correct from '../../../../../images/correct.svg';
import CreateContext from "../../../../../store/create-context";
import WebSocketInstance from "../../../../../common/WebSocketInstance";
import { set } from "date-fns";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import commonStyles from '../../../../../common/styles/common.module.css'; // Import common styles

const AddMultipleGitRepoModal = (props) => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  let urlPlaceholderText;
  const [mode, setMode] = useState("addNew");
  const [inputUrls, setInputUrls] = useState([
    {
      url: "",
      projects: [{ projectType: "library", command: "", envVars: [""], pLanguage: "python", startDir: "" }]
    }
  ]);
  const [syncedUrls, setSyncedUrls] = useState([]);
  const [enableBtn, setEnableBtn] = useState(false);

  switch (environment) {
    case "prod":
        urlPlaceholderText = "Enter Git repository URL to connect";
        break;
    case "dev":
        urlPlaceholderText = "Enter Git repository URL to connect";
        break;
    case "local_disk":
        urlPlaceholderText = "Enter Git repository URL or full local path to connect";
        break;
    default:
        urlPlaceholderText = "Enter Git repository URL to connect";
  }

  useEffect(() => {
    const hasValidUrls = inputUrls.some(urlObj => urlObj.url.trim() !== "");
    setEnableBtn(hasValidUrls);
  }, [inputUrls]);

  const handleGitConnectStatusResponse = (response) => {
    console.log("Git connect status response: ", response);
    if (response && response.git_containers) {
      setSyncedUrls(response.git_containers);
    } else {
      console.error("Invalid response: ", response);
    }
  }

  useEffect(() => {
    if (mode === "synced") {
      const socket = WebSocketInstance.getInstance();

      const onGetGitConnectStatus = (response) => {
        handleGitConnectStatusResponse(response);
      };

      socket.addEventListener("get_git_connect_status", onGetGitConnectStatus);

      socket.send('get_git_connect_status', {
        project_id: sessionStorage.getItem('jml_workspace_id')
      });

      return () => {
        socket.removeEventListener("get_git_connect_status", onGetGitConnectStatus);
      };
    }
  }, [mode]);

  const ctx = useContext(CreateContext);

  const modalCloseHandler = () => {
    props.handleClose();
  };

  const toggleMode = (newMode) => {
    setMode(newMode);
  };

  const handleInputChange = (index, event, field) => {
    const values = [...inputUrls];
    values[index][field] = event.target.value;
    setInputUrls(values);
  };

  const handleProjectChange = (urlIndex, projectIndex, field, event) => {
    const values = [...inputUrls];
    values[urlIndex].projects[projectIndex][field] = event.target.value;
    setInputUrls(values);
  };

  const handleEnvVarChange = (urlIndex, projectIndex, envIndex, event) => {
    const values = [...inputUrls];
    values[urlIndex].projects[projectIndex].envVars[envIndex] = event.target.value;
    setInputUrls(values);
  };

  const handleAddUrl = () => {
    setInputUrls([...inputUrls, { url: "", projects: [{ projectType: "library", command: "", envVars: [""], pLanguage: "python", startDir: "" }] }]);
  };

  const handleRemoveUrl = (index) => {
    const values = [...inputUrls];
    values.splice(index, 1);
    setInputUrls(values);
  };

  const handleAddProject = (urlIndex) => {
    const values = [...inputUrls];
    values[urlIndex].projects.push({ projectType: "library", command: "", envVars: [""], pLanguage: "python", startDir: "" });
    setInputUrls(values);
  };

  const handleRemoveProject = (urlIndex, projectIndex) => {
    const values = [...inputUrls];
    values[urlIndex].projects.splice(projectIndex, 1);
    setInputUrls(values);
  };

  const handleAddEnvVar = (urlIndex, projectIndex) => {
    const values = [...inputUrls];
    values[urlIndex].projects[projectIndex].envVars.push("");
    setInputUrls(values);
  };

  const handleRemoveEnvVar = (urlIndex, projectIndex, envIndex) => {
    const values = [...inputUrls];
    values[urlIndex].projects[projectIndex].envVars.splice(envIndex, 1);
    setInputUrls(values);
  };

  const handleConnect = () => {
    const nonEmptyUrls = inputUrls.filter((input) => input.url.trim() !== "");
    setSyncedUrls((prevUrls) => [...prevUrls, ...nonEmptyUrls.map(input => input.url)]);
    setInputUrls([{ url: "", projects: [{ projectType: "library", command: "", envVars: [""], pLanguage: "python", startDir: "" }] }]);
    linkSvgClickHandler(nonEmptyUrls);
    modalCloseHandler()
  };

  const linkSvgClickHandler = (nonEmptyUrls) => {
    nonEmptyUrls.forEach(input => {
      if (input.url !== "") {
        gitClickHandler(
          ctx,
          input,
          props.setCheckActivity,
          props.setPendingActivity,
          props.setShowToastr,
          props.togglePlusDropdown
        );
      }
    });
  };

  function gitClickHandler(
    ctx,
    input,
    setCheckActivity,
    setPendingActivity,
    setShowToastr,
    togglePlusDropdown
  ) {
    console.log("Cache option selected.", ctx.useCache);
    const socket = WebSocketInstance.getInstance();
    socket.send("git_connect", {
      git_url: input.url,
      project_id: sessionStorage.getItem("jml_workspace_id"),
      use_cache: ctx.useCache,
      projects: input.projects,
    });
    setCheckActivity(true);
    setPendingActivity(true);
    setShowToastr({
      show: true,
      type: "info",
      text: "We are working on your repository",
    });
    // togglePlusDropdown && togglePlusDropdown();
  }

  // Add these functions to handle auto-filling details
  const autoFillAirlineBooking = () => {
    const predefinedDetails = [
      {
        url: "https://github.com/aws-samples/aws-serverless-airline-booking/tree/archive",
        projects: [
          {'projectType': 'lambda',
          'command': 'notify:lambda_handler',
          'envVars': ['BOOKING_TABLE_NAME=Booking'],
          'pLanguage': 'python',
          'startDir': 'src/backend/booking/src/notify-booking'},
          {'projectType': 'lambda',
          'command': 'cancel:lambda_handler',
          'envVars': ['BOOKING_TABLE_NAME=Booking'],
          'pLanguage': 'python',
          'startDir': 'src/backend/booking/src/cancel-booking'},
          {'projectType': 'lambda',
          'command': 'confirm:lambda_handler',
          'envVars': ['BOOKING_TABLE_NAME=Booking'],
          'pLanguage': 'python',
          'startDir': 'src/backend/booking/src/confirm-booking'},
          {'projectType': 'lambda',
          'command': 'reserve:lambda_handler',
          'envVars': ['BOOKING_TABLE_NAME=Booking'],
          'pLanguage': 'python',
          'startDir': 'src/backend/booking/src/reserve-booking'},
          {'projectType': 'lambda',
          'command': 'release:lambda_handler',
          'envVars': ['FLIGHT_TABLE_NAME=Flight'],
          'pLanguage': 'python',
          'startDir': 'src/backend/catalog/src/release-flight'},
          {'projectType': 'lambda',
          'command': 'reserve:lambda_handler',
          'envVars': ['FLIGHT_TABLE_NAME=Flight'],
          'pLanguage': 'python',
          'startDir': 'src/backend/catalog/src/reserve-flight'},
          {'projectType': 'lambda',
          'command': 'collect:lambda_handler',
          'envVars': ['FLIGHT_TABLE_NAME=Flight'],
          'pLanguage': 'python',
          'startDir': 'src/backend/payment/src/collect-payment'},
          {'projectType': 'lambda',
          'command': 'refund:lambda_handler',
          'envVars': ['FLIGHT_TABLE_NAME=Flight'],
          'pLanguage': 'python',
          'startDir': 'src/backend/payment/src/refund-payment'},
          ]
      }
    ];
    setInputUrls(predefinedDetails);
  };

  const autoFillPythonIOTApp = () => {
    const predefinedDetails = [
      {
        url: "https://github.com/aws-samples/aws-appsync-iot-core-realtime-dashboard",
        projects: [
          {
            projectType: "executable",
            pLanguage: "javascript",
            startDir: "web",
            command: "npm dev",
            envVars: []
          }
        ]
      }
    ];
    setInputUrls(predefinedDetails);
  };

  return (
    <div>
      <ModalUI
        show={props.show}
        handleClose={modalCloseHandler}
        theme="dark"
        classes="border-no pb-0 m-0"
        modalTitle="Sync Code Repos"
        size="md"
      >
        <div className={styles.model_container}>
          <Row className={styles.custom_button_container}>
            <Col>
              <p className={styles.heading}>
                You can add multiple respositories at a time
              </p>
              <div className={styles.button_container}>
                <ul className={`nav nav-tabs ${styles.nav_tabs}`} role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ml-1 ${mode === "addNew" ? styles.active_nav_link : styles.nav_links} ${styles.nav__links}`}
                      type="button"
                      role="tab"
                      aria-controls="home"
                      onClick={() => toggleMode("addNew")}
                      style={{
                        marginLeft: "2rem",
                        borderTop: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "addNew" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "addNew" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "addNew" ? "100%" : "50%",
                      }}
                    >
                      Add new
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${mode === "synced" ? styles.active_nav_link : styles.nav_links} ${styles.nav__links}`}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      onClick={() => toggleMode("synced")}
                      style={{
                        borderTop: mode === "synced" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "synced" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "synced" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "synced" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "synced" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "synced" ? "100%" : "50%",
                      }}
                    >
                      Synced
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {mode === "addNew" && (
            <div className={styles.add_new_main_div}>
              <div className={`mt-2 ${styles.addNew_container}`}>
                <div className={`mt-4`}>
                  <div className="d-flex justify-content-between">
                    <p className={`opacity-50 ${styles.heading_synced}`}>
                      Please provide code repository details
                    </p>
                    <div className={`dropdown ${styles.auto_fill_dropdown}`}>
                      <button
                        className={`btn btn-secondary dropdown-toggle ${commonStyles.auto_fill_button}`}
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Try a Demo Repo
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                          <button className="dropdown-item" onClick={autoFillAirlineBooking} title="https://github.com/aws-samples/aws-serverless-airline-booking">
                            Airline Booking Appln
                          </button>
                        </li>
                        <li>
                          <button className="dropdown-item" onClick={autoFillPythonIOTApp} title="https://github.com/aws-samples/aws-appsync-iot-core-realtime-dashboard">
                            JavaScript IOT App
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {inputUrls.map((input, urlIndex) => (
                    <div key={urlIndex} style={{ border: "1px solid #4A4A4A", borderRadius: "10px", padding: "10px", marginBottom: "10px" }}>
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          placeholder={urlPlaceholderText}
                          value={input.url}
                          onChange={(e) => handleInputChange(urlIndex, e, 'url')}
                          className={`mb-3 ${styles.url_inputbox}`}
                        />
                      </div>
                      {input.projects.map((project, projectIndex) => (
                        <div key={projectIndex} style={{ border: "1px solid #4A4A4A", borderRadius: "10px", padding: "10px", marginBottom: "10px", position: "relative" }}>
                          <div className="project" style={{ display: "flex"}}>
                            <select
                              value={project.projectType}
                              onChange={(e) => handleProjectChange(urlIndex, projectIndex, 'projectType', e)}
                              className={`mb-3 ${styles.dropdown}`}
                              style={{ marginRight: "15px" }}
                            >
                              <option value="library">Library</option>
                              <option value="executable">Executable</option>
                              <option value="lambda">Lambda</option>
                            </select>
                            <select
                              value={project.pLanguage}
                              onChange={(e) => handleProjectChange(urlIndex, projectIndex, 'pLanguage', e)}
                              className={`mb-3 ${styles.dropdown}`}
                              style={{ marginRight: "15px" }}
                            >
                              <option value="python">Python</option>
                              <option value="javascript">JavaScript</option>
                            </select>
                            <button
                              className={`mb-3 ${styles.remove_button}`}
                              onClick={() => handleRemoveProject(urlIndex, projectIndex)}
                              style={{ padding: "5px 10px" }}
                            >
                              Remove property
                            </button>
                          </div>
                          <input
                            type="text"
                            placeholder="Enter the container directory"
                            value={project.startDir}
                            onChange={(e) => handleProjectChange(urlIndex, projectIndex, 'startDir', e)}
                            className={`mb-3 ${styles.inputbox}`}
                          />
                          {project.projectType === "executable" && (
                            <div className={styles.executable_inputs}>
                              <input
                                type="text"
                                placeholder="Enter the complete command"
                                value={project.command}
                                onChange={(e) => handleProjectChange(urlIndex, projectIndex, 'command', e)}
                                className={`mb-3 ${styles.inputbox}`}
                              />
                            </div>
                          )}
                          {project.projectType === "lambda" && (
                            <div className={styles.executable_inputs}>
                              <input
                                type="text"
                                placeholder="Enter the module name and lambda handler"
                                value={project.command}
                                onChange={(e) => handleProjectChange(urlIndex, projectIndex, 'command', e)}
                                className={`mb-3 ${styles.inputbox}`}
                              />
                            </div>
                          )}
                          {project.envVars.map((env, envIndex) => (
                            <div key={envIndex} style={{ position: "relative", display: "flex", justifyContent: "space-between"}}>
                              <input
                                type="text"
                                placeholder="Enter environment variable"
                                value={env}
                                onChange={(e) => handleEnvVarChange(urlIndex, projectIndex, envIndex, e)}
                                className={`mb-3 ${styles.env_inputbox}`}
                              />
                              <button
                                className={`mb-3 ${styles.remove_button}`}
                                onClick={() => handleRemoveEnvVar(urlIndex, projectIndex, envIndex)}
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <button
                            className={`mb-3 ${styles.add_button}`}
                            onClick={() => handleAddEnvVar(urlIndex, projectIndex)}
                          >
                            + Add environment variable
                          </button>
                        </div>
                      ))}
                      <button
                        className={`mb-3 ${styles.add_button}`}
                        onClick={() => handleAddProject(urlIndex)}
                      >
                        + Add repository properties
                      </button>
                      <button
                        className={`mb-3 ${styles.remove_repo_button}`}
                        onClick={() => handleRemoveUrl(urlIndex)}
                      >
                        Remove this repository
                      </button>
                    </div>
                  ))}
                  <button
                    className={`mb-4 ${styles.add_button}`}
                    onClick={handleAddUrl}
                  >
                    + Add repository
                  </button>
                </div>
                <div className={styles.instructions_container}>
                  <div className="px-4 py-3 opacity-50">
                    Instructions:-
                    <ul className="mb-0 mt-2">
                      <li className="opacity-50">
                        Copy the GitHub URL with the branch
                      </li>
                      <li className={`opacity-50 ${styles.instructions_content}`}>
                        Add "jigsawml" as a collaborator to your private
                        repository
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  className={enableBtn ? `${styles["enable_connect_btn"]}` : `${styles["connect_button"]}`}
                  onClick={handleConnect}
                >
                  <img src={correct} alt="Connect" />
                  Connect
                </button>
              </div>
            </div>
          )}
          {mode === "synced" && (
            <div>
              <p className={` mt-4 ${styles.heading_synced}`}>
                Previously connected code repos
              </p>
              <div className={`mt-4 ${styles.synced_container}`}>
                <ul>
                  {<ul>
                    {syncedUrls.map((item, index) => (
                        <li key={index} className={styles.synced_list}>
                        <div className={styles.syncedUrls}>
                            <span className={styles.url}>{item.url}</span>
                            {item.p_containers.map((container, idx) => (
                            <div key={idx} className={styles.containerDetails}>
                                <div className={styles.containerItem}><strong>Container Name:</strong> {container.container_name || 'N/A'}</div>
                                <div className={styles.containerItem}><strong>Language:</strong> {container.pLanguage}</div>
                                <div className={styles.containerItem}><strong>Project Type:</strong> {container.projectType}</div>
                                <div className={styles.containerItem}><strong>Status:</strong> {container.processing_status}</div>
                                <div className={styles.containerItem}><strong>Start Directory:</strong> {container.start_dir}</div>
                                <div className={styles.containerItem}><strong>Environment:</strong> {container.env.join(', ') || 'N/A'}</div>
                                <div className={styles.containerItem}><strong>Command:</strong> {container.command}</div>
                            </div>
                            ))}
                        </div>
                        </li>
                    ))}
                    </ul>}
                </ul>
              </div>
            </div>
          )}
        </div>
      </ModalUI>
    </div>
  );
};

export default AddMultipleGitRepoModal;
