import Card from "react-bootstrap/Card";
import vectorArrow from "../../../images/Vector_white.svg";
import Accordion from "react-bootstrap/Accordion";
import downArrow from "../../../images/downVector.svg";
import {removeDuplicates, resourceIcon} from "./Regions";
import "./Vpcs.css";
import { useEffect, useState } from "react";
import Subnet from "./Subnet";

const Vpcs = ({ vpc, subnet, searchBar,
   handleResourceIconClick, CustomToggle,
   openCards, highlightText, allOpen,
   icons, openAccordionForItem, setOpenCards
  }) => {

  const [openParentCards, setOpenParentCards] = useState({});
  const [openChildCards, setOpenChildCards] = useState({});
  const [openGrandchildCards, setOpenGrandchildCards] = useState({});

  const toggleParentCard = (index) => {
    setOpenParentCards((prev) => {
      const newState = { ...prev };
      newState[index] = !prev[index];
      return newState;
    });
  };

  const toggleChildCard = (id) => {
    setOpenChildCards((prev) => {
      const newState2 = { ...prev };
      newState2[id] = !prev[id];
      return newState2;
    });
  };

  const toggleGrandchildCard = (idx) => {
    setOpenGrandchildCards((prev) => {
      const newState3 = { ...prev };
      newState3[idx] = !prev[idx];
      return newState3;
    });
  };

  const handleOpenAccordionForItem = (
    openAccordionForItem,
    vpc,
    toggleParentCard,
    toggleChildCard,
    toggleGrandchildCard,
    callback
  ) => {
    if (openAccordionForItem && openAccordionForItem.id) {
      Object.keys(vpc?.children?.categories).forEach((category) => {
        const uniqueItems = removeDuplicates(vpc?.children?.categories[category]);
        Object.keys(uniqueItems).forEach((el) => {
          if (openAccordionForItem.type?.includes(":") && openAccordionForItem.type.split(":")[0] === el) {
            callback(vpc.id);
            toggleParentCard(category);
            Object.keys(uniqueItems[el]).forEach((element) => {
              if (openAccordionForItem.type?.includes(":") && openAccordionForItem.type.split(":")[1] === element) {
                toggleChildCard(el);
              }
              uniqueItems[el][element].forEach((item) => {
                if (openAccordionForItem.name === item.name) {
                  toggleGrandchildCard(element);
                }
              });
            });
          }
        });
      });
    }
  };

  useEffect(() => {
    handleOpenAccordionForItem(
      openAccordionForItem,
      vpc,
      toggleParentCard,
      toggleChildCard,
      toggleGrandchildCard,
      (id) => {
        setOpenCards((prev) => {
          const newState = { ...prev };
          newState[id] = !prev[id];
          return newState;
        });
      }
    );
  }, [openAccordionForItem]);

  return (
    <Accordion>
      <Card className="vpcs-expansion">
        <CustomToggle eventKey={vpc.id}>
          <Card.Header
            className={`vpc-details ${ allOpen
              ? (!openCards[vpc.id] ? "no-border-bottom" : "")
              : (openCards[vpc.id] ? "no-border-bottom" : "")
            }`}
            style={{
              borderBottomLeftRadius: allOpen
               ? (!openCards[vpc.id] ? "0%" : "16px")
               : (openCards[vpc.id] ? "0%" : "16px"),
              borderBottomRightRadius: allOpen
               ? (!openCards[vpc.id] ? "0%" : "16px")
               : (openCards[vpc.id] ? "0%" : "16px"),
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
          >
            <div className="vpc-subnet">
              <span className="vpc-btn">
                {resourceIcon(vpc.type?.includes(":") &&
                  vpc.type.split(":")[1])}
                <span className="vpcs-name">
                  {highlightText(vpc.name, searchBar)}
                </span>
              </span>
              <span>
                <img
                  src={vectorArrow}
                  alt="vectorArrow"
                  className = {allOpen
                    ? (!openCards[vpc.id] ? 'uparrow' : '')
                    : (openCards[vpc.id] ? 'uparrow' : '')}
                />
              </span>
            </div>
          </Card.Header>
        </CustomToggle>
        <Accordion.Collapse
          eventKey={vpc.id}
          style={{ background: "rgba(24, 25, 38, 1)"}}
          in={allOpen ? !openCards[vpc.id] : openCards[vpc.id]}
        >
          <Card.Body className="vpcs-body">
            <Accordion>
              {Object.keys(vpc?.children?.categories).map((category, index) => {
                  const uniqueItems = removeDuplicates(vpc?.children?.categories[category]);
                  return (
                    <div key={category}>
                    <Card
                      className= "category-compute-data"
                    >
                      <CustomToggle eventKey={category}>
                        <Card.Header className="category-header" onClick={() => toggleParentCard(category)}>
                          <span className="category-btn">
                            <span>{icons[category]}</span>
                            <span>{category}</span>
                          </span>
                          <span className="vpc-category-count">
                            <span className="categories-count">
                            {Object.keys(uniqueItems).length}
                            </span>
                            <span>
                              <img
                              src={downArrow}
                              alt=""
                              className = {allOpen
                                ? (!openParentCards[category] ? 'uparrow' : '')
                                : (openParentCards[category] ? 'uparrow' : '')}
                              />
                            </span>
                          </span>
                        </Card.Header>
                      </CustomToggle>
                      <Accordion.Collapse
                        eventKey={category}
                        in={allOpen ? !openParentCards[category] : openParentCards[category]}
                      >
                        <Card.Body className="category-body">
                          <Accordion>
                            {Object.keys(uniqueItems).map((el, idx) => {
                                return (
                                  <Card
                                    className="category-details"
                                    key={idx}
                                  >
                                    <CustomToggle eventKey={idx}>
                                      <Card.Header className="category-details-vpc-header" onClick={() => toggleChildCard(el)}>
                                        <span className="category-btn">
                                          {resourceIcon(el)}
                                          <span className="body-data">
                                          {highlightText(el, searchBar)}
                                          </span>
                                        </span>
                                        <span className="compute-btn">
                                          <span className="categories-count">
                                            {Object.keys(uniqueItems[el]).length}
                                          </span>
                                          <img
                                            src={downArrow}
                                            alt=""
                                            className = {allOpen
                                              ? (!openChildCards[el] ? "arrow-up" : "vector-arrow")
                                              : (openChildCards[el] ? "arrow-up" : "vector-arrow")}
                                          />
                                        </span>
                                      </Card.Header>
                                    </CustomToggle>
                                    <Accordion.Collapse
                                      eventKey={idx}
                                      in={allOpen ? !openChildCards[el] : openChildCards[el]}
                                    >
                                      <Card.Body className="category-details-body">
                                        <Accordion>
                                        {Object.keys(uniqueItems[el]).map((element, innerIdx) => (
                                          <Card
                                            className="category-details"
                                            key={innerIdx}
                                          >
                                            <CustomToggle
                                              eventKey={innerIdx}
                                            >
                                              <Card.Header className="category-details-vpc-header" onClick={() => toggleGrandchildCard(element)}>
                                                <span className="category-btn">
                                                  {resourceIcon(element)}
                                                  <span className="body-data">
                                                  {highlightText(element, searchBar)}
                                                  </span>
                                                </span>
                                                <span className="compute-btn">
                                                  <span className="categories-count">
                                                    {uniqueItems[el][element].length}
                                                  </span>
                                                  <img
                                                    src={downArrow}
                                                    alt=""
                                                    className = {allOpen
                                                      ? (!openGrandchildCards[element] ? "arrow-up" : "vector-arrow")
                                                      : (openGrandchildCards[element] ? "arrow-up" : "vector-arrow")}
                                                  />
                                                </span>
                                              </Card.Header>
                                            </CustomToggle>
                                            <Accordion.Collapse
                                              eventKey={innerIdx}
                                              in={allOpen ? !openGrandchildCards[element] : openGrandchildCards[element]}
                                            >
                                              <Card.Body className="category-details-body">
                                                {uniqueItems[el][element].map((item, itemIdx) => (
                                                  <div key={itemIdx} className="instances"
                                                    onClick={(e) => handleResourceIconClick(e, item)}
                                                  >
                                                    <span>{resourceIcon(item.type?.includes(":") &&
                                                      item.type.split(":")[1])}</span>
                                                    <span className="body-data">{highlightText(item.name.length>10
                                                      ? item.name.slice(0,10)+"..." : item.name, searchBar)}
                                                    </span>
                                                  </div>
                                                ))}
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>))}
                                        </Accordion>
                                      </Card.Body>
                                    </Accordion.Collapse>
                                  </Card>
                                );
                              }
                            )}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    </div>
                  );
                })}
            </Accordion>
            <Subnet
             searchBar={searchBar}
              openCards={openCards} CustomToggle={CustomToggle}
              handleResourceIconClick={handleResourceIconClick}
              highlightText={highlightText} allOpen={allOpen}
              subnet={subnet} openAccordionForItem={openAccordionForItem}
              icons={icons} handleOpenAccordionForItem={handleOpenAccordionForItem}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default Vpcs;
