import { useContext, useEffect } from "react";
import { CodeFrameSvg, DocSvg, ExternalLink } from "../../../images/svg/icons";
import infrastructure from "../../../images/infraIcon.svg"
import { Nav } from "react-bootstrap";
import context from "../../../store/create-context";
import styles from "./view-edit-architecture.module.css";

const viewsLogo = {
  // doc_view: <DocSvg />,
  code_view: window.location.host.split(".")[0] !== "scanner" &&  <CodeFrameSvg />,
  infra_view: <img src={infrastructure} alt=""/>,
};

function checkHasKey(properties, key) {
  return properties.hasOwnProperty(key);
}
const NavbarView = ({ sResponse }) => {
  /*context*/
  const { activeSection, setActiveSection } = useContext(context);
  const scannerMode = window.location.host.split(".")[0] !== "scanner"

  let allViews = {
    default: scannerMode && "doc_view",
    views: [
      // {
      //   name: "doc_view",
      //   display_name: "Doc View",
      // },
      {
        name: scannerMode &&  "code_view",
        display_name: scannerMode && "Code",
      },
      {
        name: "infra_view",
        display_name: "Infra",
      },
    ],
  };

  if (sResponse.display_views) {
    sResponse.display_views?.views.forEach((view) => {
      if (
        view.name !== "doc_view" &&
        view.name !== "code_view" &&
        view.name !== "infra_view"
      )
        allViews.views.push(view);
    });
    if (sResponse.display_views.default)
      allViews.default = sResponse.display_views.default;
  }
const checkKeyAndSetActiveSec = (sResponse) => {
    if(sResponse.hasOwnProperty("display_views") && sResponse.display_views.default && sResponse.hasOwnProperty(sResponse.display_views.default)){
      setActiveSection(sResponse.display_views.default);
    }else if(sResponse.hasOwnProperty("doc_view")  && scannerMode){
      setActiveSection("doc_view");
    }else if(sResponse.hasOwnProperty("code_view") && scannerMode){
      setActiveSection("code_view")
    }else if(sResponse.hasOwnProperty("infra_view")){
      setActiveSection("infra_view")
    }else{
      setActiveSection("");
    }
}
  useEffect(() => {
    if (sResponse !== "" && activeSection === "") {
      checkKeyAndSetActiveSec(sResponse);
    }
  }, [sResponse]);

  const handleNavView = (sResponse, viewName) =>{
    if(scannerMode){
     checkHasKey(sResponse, viewName) && setActiveSection(viewName)
    }
  }

  return allViews?.views.map((view, index) => (
    <div
      key={index}
      style={{
        minWidth: "80px",
        borderLeft: activeSection === "infra_view" ? "1px solid rgba(7, 8, 15, 1)" : "1px solid rgba(98, 98, 98, 1)"
      }}
      className={Object.keys(sResponse).length === 0 ? styles["views-name"] : ''}
    >
      <Nav.Link
        className="h-100 d-flex flex-column justify-content-center align-items-center gap-1"
        style={{
          background: activeSection == view.name  ? "#5A46FF" : "rgba(24, 25, 38, 1)",
          cursor: checkHasKey(sResponse, view.name) && scannerMode ? "pointer" : "not-allowed",
          borderTopRightRadius: view.name === "infra_view" ? "4px" : "",
          borderEndEndRadius: view.name === "infra_view" ? "4px" : "",
          borderTopLeftRadius: view.name === "code_view" ? "4px" : "",
          borderBottomLeftRadius: view.name === "code_view" ? "4px" : "",
        }}
        onClick={() => handleNavView(sResponse, view.name)}
      >
        {viewsLogo[view.name] ?? (scannerMode && <DocSvg />)}
        <span
          style={{ fontSize: "12px", fontFamily: "Work Sans" }}
          className="fw-semibold"
        >
          {view.display_name}
        </span>
      </Nav.Link>
    </div>
  ));
};

export default NavbarView;
