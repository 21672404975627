import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../../../NewLanding2/Footer";
import flyerImage from "../../../../assets/cloudx_flyer.png";
import CloudxFlyerPDF from "../../../../assets/cloudx_flyer.pdf";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import useFormHandler from '../../../NewLanding2/useFormHandler';
import AccessModal from '../../../NewLanding2/AccessModal';
import ThanksModal from '../../../NewLanding2/ThanksModal';

const FlyersIndex = () => {
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  const {
    email,
    name,
    affiliation,
    message,
    isValidEmail,
    emailError,
    showAccessModal,
    showThanksModal,
    isSubmitting,
    formRef,
    setEmailHandler,
    setNameHandler,
    setAffiliationHandler,
    setMessageHandler,
    formSubmitHandler,
    setEmail,
    setName,
    setAffiliation,
    setMessage,
    setIsValidEmail,
    setEmailError,
    setShowAccessModal,
    setShowThanksModal,
  } = useFormHandler();

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = CloudxFlyerPDF;
    link.download = "jml_cloudx_flyer.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleShareModal = () => {
    setShareModalOpen((prev) => !prev);
  };

  const shareOnSocialMedia = (platform) => {
    const url = 'https://www.jigsawml.com';
    const message = `Discover the latest in cloud innovation! JigsawML is an AI-powered Cloud Intelligence Platform designed to transform the way you manage cloud services. Learn more here: ${url}.`;
    let shareUrl;

    switch (platform) {
      case "linkedin":
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&text=${encodeURIComponent(message)}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Hey! Have you seen this latest cloud platform?&body=${encodeURIComponent(message)}`;
        break;
      case "copy":
        navigator.clipboard.writeText(message)
          .then(() => alert("URL copied to clipboard!"))
          .catch((error) => console.error("Failed to copy URL: ", error));
        return;
      default:
        return;
    }

    window.open(shareUrl, "_blank");
  };

  return (
    <div>
      <Header />
      <div className="flyer-container">
        <img
          src={flyerImage}
          alt="CloudX Flyer"
          style={{ width: '60%', margin: '2rem', borderRadius: '8px' }}
        />
      </div>
      <div className="actions-container" style={{ textAlign: 'center', margin: '2rem 0' }}>
        <button onClick={handleDownload} style={buttonStyle}>
          Download the PDF
        </button>
        <button onClick={toggleShareModal} style={buttonStyle}>
          Share the Website
        </button>
      </div>
      <button
            style={architectureButtonStyle}
            type="button"
            onClick={() => {
              setEmail("");
              setName("");
              setAffiliation("");
              setMessage("");
              setIsValidEmail(null);
              setEmailError(false);
              setShowAccessModal(true);
            }}
          >
            Get your architecture for free
        </button>
        <AccessModal
          show={showAccessModal}
          handleClose={() => setShowAccessModal(false)}
          formRef={formRef}
          name={name}
          affiliation={affiliation}
          message={message}
          email={email}
          isValidEmail={isValidEmail}
          emailError={emailError}
          isSubmitting={isSubmitting}
          setNameHandler={setNameHandler}
          setAffiliationHandler={setAffiliationHandler}
          setMessageHandler={setMessageHandler}
          setEmailHandler={setEmailHandler}
          formSubmitHandler={formSubmitHandler}
        />
      <ThanksModal
        show={showThanksModal}
        handleClose={() => setShowThanksModal(false)}
      />

      {isShareModalOpen && (
        <div className="share-modal" style={modalStyle}>
          <div style={modalContentStyle}>
            <div style={buttonGroupStyle}>
              <div style={iconContainerStyle}>
                <button onClick={() => shareOnSocialMedia('linkedin')} style={iconButtonStyle}>
                  <FontAwesomeIcon icon={faLinkedin} />
                </button>
                <span style={iconTextStyle}>Share on LinkedIn</span>
              </div>
              <div style={iconContainerStyle}>
                <button onClick={() => shareOnSocialMedia('email')} style={iconButtonStyle}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </button>
                <span style={iconTextStyle}>Send as Email</span>
              </div>
              <div style={iconContainerStyle}>
                <button onClick={() => shareOnSocialMedia('copy')} style={iconButtonStyle}>
                  <FontAwesomeIcon icon={faClipboard} />
                </button>
                <span style={iconTextStyle}>Copy to Clipboard</span>
              </div>
            </div>
            <button onClick={toggleShareModal} style={closeButtonStyle}>
              Close
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

// Styles
const buttonStyle = {
  backgroundColor: 'rgba(90, 70, 255, 1)',
  color: '#ffffff',
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  margin: '0 10px',
};

const architectureButtonStyle = {
  backgroundColor: 'rgba(90, 70, 255, 1)',
  color: '#ffffff',
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  marginBottom: '20px',
};

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#ffffff',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  maxWidth: '400px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const buttonGroupStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginBottom: '20px',
  marginTop: '20px',
  fontSize: '100px',
};

const iconContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const iconButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '36px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
};

const iconTextStyle = {
  marginTop: '5px',
  fontSize: '14px',
  color: '#333',
};

const closeButtonStyle = {
  backgroundColor: 'rgba(90, 70, 255, 1)',
  color: '#ffffff',
  border: 'none',
  borderRadius: '5px',
  padding: '5px 10px',
  cursor: 'pointer',
};

export default FlyersIndex;
