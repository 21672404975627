import React, { useState, useContext, useEffect, useCallback } from "react";
import ModalUI from "../../../UI/ModalUI/ModalUI";
import { Row, Col } from "react-bootstrap";
import Azure1 from "../../../../../images/Azure1.svg";
import Azure2 from "../../../../../images/Azure2.svg";
import awsImg from "../../../../../images/aws.svg";
import googleCloud from "../../../../../images/GoogleCloud.svg";
import progress from "../../../../../images/StateProgress.svg";
import completed from "../../../../../images/StateCompleted.svg";
import failed from "../../../../../images/StateFailed.svg";
import disconnect from "../../../../../images/disconnect.svg";
import reset from "../../../../../images/reset.svg";
import context from "../../../../../store/create-context";
import styles from "../AddConnectCloudModal/AddConnectCloudModal.module.css";
import correct from "../../../../../images/correct.svg";
import WebSocketInstance from "../../../../../common/WebSocketInstance";
import Toastify from "../../../../../common/components/Toastify/Toastify";
import commonStyles from '../../../../../common/styles/common.module.css'; // Import common styles

const AddConnectCloudModal = (props) => {
  const ctx = useContext(context);
  const [mode, setMode] = useState("addNew");
  const [arnRole, setArnRole] = useState("");
  const [allArnRoles, setAllArnRoles] = useState([]);
  const [selectedServer, setSelectedServer] = useState();
  const [awsAccountId, setAwsAccountId] = useState("");
  const [externalId, setExternalId] = useState("");
  const [pastExternalIds, setPastExternalIds] = useState("");
  const [invalidArnRole, setInvalidArnRole] = useState(false);
  const [arnStatusMap, setArnStatusMap] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isDemoAccountSelected, setIsDemoAccountSelected] = useState(false);
  const socket = WebSocketInstance.getInstance();

  useEffect(()=>{
    return ()=>{
      if (socket?.socketRef) {
      socket.socketRef.removeAllListeners('cloud_aws_resolve');
      }
    }
  },[props.show, mode, socket.socketRef])

  const modalCloseHandler = () => {
    props.handleClose();
    setPastExternalIds("");
    setExternalId("");
    setAwsAccountId("");
    setErrorMessage("");
    setIsDemoAccountSelected(false)
  };

  const toggleMode = (newMode) => {
    setMode(newMode);
  };

  const handleChange = (e) => {
    e.stopPropagation();
    setArnRole(e.target.value);
  };

  const handleAccountIdChange = (e) => {
    setAwsAccountId(e.target.value);
  };

  const isValidAccountId = (accountId) => {
    const accountIdRegex = /^\d{12}$/;
    return accountIdRegex.test(accountId);
  };

  const isValidArnRole = (arnRole) => {
    const arnRegex = /^arn:(.*):iam:(.*)$/;
    return arnRegex.test(arnRole);
  };

  const handleExternalIdResponse = (response) => {
    setPastExternalIds("");
    setErrorMessage("");
    if (response?.success) {
      if (response.external_id !== null && response.external_id !== undefined && response.external_id !== "") {
        setExternalId(response.account_id + ": " + response.external_id);
      } else {
        setErrorMessage("Failed to fetch External ID. Please retry!");
      }
    } else {
      // setExternalId("Failed to fetch External ID. Please retry!");
      setErrorMessage(response.error);
    }
  };

  const handlePastExternalIdsResponse = (response) => {
    if (response?.success) {
      if (response.past_external_ids && Object.keys(response.past_external_ids).length > 0) {
        setPastExternalIds(response.past_external_ids);
        setExternalId("");
        setErrorMessage("");
      } else {
        setErrorMessage("It looks like you don't have any previously created External IDs in this project.");
      }
    } else {
      setErrorMessage(response.error);
    }
  };

  const handleArnStatusResponse = (response) => {
    if (response?.success) {
      if (response.iam_role_arn_with_status_map !== null) {
        setArnStatusMap(response.iam_role_arn_with_status_map);
      } else {
        setArnStatusMap(["Uh oh! It looks like you have not connected any cloud account yet."]);
      }
    } else {
      setArnStatusMap(["Failed to get the list of connected cloud accounts. Please retry!"]);
    }
  };

  function handleCloudAwsConnect(response) {
    if (response?.success === false) {
      ctx.setShowToastr({
        show: true,
        type: "error",
        text: response?.error,
      });
    }
  }

  function sendCloudAwsResolveQuery(socket, awsAccountId) {
    socket.send("cloud_aws_resolve", {
      account_id: awsAccountId,
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_acc_id_and_ext_id"
    });
    socket.addEventListener("cloud_aws_resolve", handleExternalIdResponse);
  }
    
  const getAwsExtIdFromBackend = () => {
    if (isDemoAccountSelected || isValidAccountId(awsAccountId)) {
      sendCloudAwsResolveQuery(socket, awsAccountId);
    } else {
      setErrorMessage("Please provide a valid AWS account ID.");
      setExternalId("");
    };
  };

  const showPastExternalIds = () => {    
    setAwsAccountId("");
    socket.send("cloud_aws_resolve", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_acc_id_and_ext_id_map"
    });
    socket.addEventListener("cloud_aws_resolve", handlePastExternalIdsResponse);

  };

  const giveAccess = () => {
    if (!isValidArnRole(arnRole)) {
      setInvalidArnRole(true);
      return;
    } else {
      setInvalidArnRole(false);
      setAllArnRoles((prev) => {
        return [...prev, { id: Date.now(), role: arnRole, server: selectedServer }];
      });
      setArnRole("");
      console.log("Sending allArnRoles", allArnRoles);
      linkSvgClickHandler();
    }
    modalCloseHandler()
  };

  const linkSvgClickHandler = () => {
    if (arnRole !== "" && selectedServer === awsImg) {
      cloudClickHandler(
        ctx,
        arnRole,
        props.setCheckActivity,
        props.setPendingActivity,
        props.setShowToastr,
        setArnRole,
        props.togglePlusDropdown
      );
    }
  };

  function cloudClickHandler(
    ctx,
    arnRole,
    setCheckActivity,
    setPendingActivity,
    setShowToastr,
    setArnRole,
    togglePlusDropdown
  ) {
    socket.send("cloud_aws_connect", {
      role: arnRole,
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "connect",
      use_cache: ctx.useCache,
    });
    socket.addEventListener("cloud_aws_connect", handleCloudAwsConnect);

    setCheckActivity(true);
    setPendingActivity(true);
    setShowToastr({
      show: true,
      type: "info",
      text: "We are working on connecting your cloud account for the following ARN - " + arnRole,
    });
    setArnRole("");
    // togglePlusDropdown && togglePlusDropdown();
  }

  const showArnStatus = useCallback(() => {
    socket.send("cloud_aws_resolve", {
      project_id: sessionStorage.getItem("jml_workspace_id"),
      operation: "query_iam_role_arn_with_status_map"
    });
    socket.addEventListener("cloud_aws_resolve", handleArnStatusResponse);
  }, [socket]);

  useEffect(() => {
    if (mode === "Connected") {
      const cleanupShowArnStatus = showArnStatus();
      return cleanupShowArnStatus;
    }
  }, [mode, showArnStatus]);

  useEffect(() => {
    if (isDemoAccountSelected) {
      getAwsExtIdFromBackend();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoAccountSelected]);

  const autoFillMediaReplayEngineAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('194722446719');
    setArnRole("arn:aws:iam::194722446719:role/jmltest");
    setIsDemoAccountSelected(true);
  };

  const autoFillPythonIOTAccount = () => {
    setSelectedServer(awsImg);
    setAwsAccountId('021891593773');
    setArnRole("arn:aws:iam::021891593773:role/jmltest");
    setIsDemoAccountSelected(true);
  };

  return (
    <div>
      <ModalUI
        show={props.show}
        handleClose={modalCloseHandler}
        theme="dark"
        classes="border-no pb-0 m-0"
        modalTitle="Link Cloud Accounts"
        size="md"
      >
        <div className={styles.model_container}>
          <Row className={styles.custom_button_container}>
            <Col>
              <p className={styles.heading}>
                You can add multiple cloud accounts
              </p>
              <div className={styles.button_container}>
                <ul
                  className={`nav nav-tabs ${styles.nav_tabs}`}
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        mode === "addNew" ? styles.active_nav_link : styles.nav_links
                      } ${styles.nav__links}`}
                      type="button"
                      role="tab"
                      aria-controls="home"
                      onClick={() => toggleMode("addNew")}
                      style={{
                        marginLeft: "2rem",
                        borderTop: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "addNew" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "addNew" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "addNew" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "addNew" ? "100%" : "50%",
                      }}
                    >
                      Add new
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        mode === "Connected" ? styles.active_nav_link : styles.nav_links
                      } ${styles.nav__links}`}
                      data-bs-toggle="tab"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      onClick={() => toggleMode("Connected")}
                      style={{
                        borderTop: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderLeft: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderRight: mode === "Connected" ? "1px solid #4A4A4A" : "none",
                        borderBottom: "1px solid #1e1e1e",
                        backgroundColor: mode === "Connected" ? "#1e1e1e" : "#1e1e1e",
                        color: mode === "Connected" ? "#FFFFFF" : "#FFFFFF",
                        opacity: mode === "Connected" ? "100%" : "50%",
                      }}
                    >
                      Connected
                    </button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          {mode === "addNew" && (
            <div className={styles.server_main_container}>
              <div className="mt-4">
                <div className={`mt-4 ${styles.server_container}`}>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className={`opacity-50 ${styles.heading_synced}`}>
                        Please select your cloud platform
                      </p>
                      <div className={`dropdown ${styles.auto_fill_dropdown}`}>
                        <button
                            className={`btn btn-secondary dropdown-toggle ${commonStyles.auto_fill_button}`}
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                          Try a Demo Account
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <li>
                            <button className="dropdown-item" onClick={autoFillMediaReplayEngineAccount}  title="https://github.com/awslabs/aws-media-replay-engine">
                              MediaReplay Engine App
                            </button>
                          </li>
                          <li>
                            <button className="dropdown-item" onClick={autoFillPythonIOTAccount} title="https://github.com/aws-samples/aws-appsync-iot-core-realtime-dashboard">
                              JavaScript IOT App
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className={`d-flex justify-content-around ${styles.cloud_buttons}`}>
                        <button onClick={() => setSelectedServer(Azure1)} disabled className={styles["disable-img"]}>
                          <img src={Azure1} alt="Azure1" />
                          <img src={Azure2} alt="Azure2" />
                        </button>
                        <button onClick={() => setSelectedServer(awsImg)}>
                          <img src={awsImg} alt="awsImg" />
                        </button>
                        <button onClick={() => setSelectedServer(googleCloud)} disabled className={styles["disable-img"]}>
                          <img src={googleCloud} alt="googleCloud" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {selectedServer === googleCloud && (
                    <div>
                      <p style={{ textAlign: "center" }}>Google Cloud Platform will be supported soon!</p>
                    </div>
                  )}
                  {selectedServer === Azure1 && (
                    <div>
                      <p style={{ textAlign: "center" }}>Microsoft Azure will be supported soon!</p>
                    </div>
                  )}
                  {selectedServer === awsImg && (
                    <>
                      <div className={styles.externalID_container}>
                        <div className={styles.externalID_input_container}>
                          <button
                            style={{ display: "block", whiteSpace: "pre-wrap" }}
                            onClick={showPastExternalIds}
                            className={`mb-3 ${styles.showPastExternalIds_button}`}
                          >
                            <span style={{ fontSize: "14px" }}>Show List of Account IDs and corresponding External IDs</span> <br/>
                            <span style={{ fontSize: "8px" }}>You might have previously created an IAM role for one of the IDs from the list</span>
                          </button>
                          <input
                            type="text"
                            placeholder="Enter Account ID"
                            value={awsAccountId}
                            onChange={(e) => handleAccountIdChange(e)}
                            className={`mb-3 ${styles.awsAccountId_inputbox}`}
                          />
                          <button
                            onClick={getAwsExtIdFromBackend}
                            className={`mb-3 ${styles.getExternalId_button}`}
                          >
                            Get External ID
                          </button>
                        </div>
                        <div className={styles.externalID_output_container}>
                          {externalId && (externalId !== "undefined") && (
                            <p style={{ whiteSpace: 'nowrap', textAlign: 'left', fontSize: '12px' }}>{externalId}</p>
                          )}
                          {Object.values(pastExternalIds).length > 0 && !errorMessage &&(
                            <div className={styles.externalIdTable_container}>
                              <table className={styles.externalIdTable}>
                                <thead>
                                  <tr>
                                    <th className={styles.externalIdTable_header}>Account ID</th>
                                    <th className={styles.externalIdTable_header}>External ID</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(pastExternalIds).map(([pastAccountId, pastExternalId], index) => (
                                    <tr key={index}>
                                      <td style={{ whiteSpace: 'nowrap' }}>{pastAccountId}</td>
                                      <td style={{ whiteSpace: 'nowrap' }}>{pastExternalId}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {errorMessage && (
                            <div>
                              <span className={styles.error_messages}>{errorMessage}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      {(externalId || Object.values(pastExternalIds).length > 0) && !errorMessage && (
                        <>
                          <div className={styles.arnRole_container}>
                            <input
                              type="text"
                              placeholder="Enter the IAM role ARN you created"
                              value={arnRole}
                              onChange={(e) => handleChange(e)}
                              className={` mb-3 ${styles.arnRole_inputbox}`}
                            />
                            {invalidArnRole && (
                              <p className={styles.error_message}>Please provide a valid IAM role ARN</p>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                {selectedServer === awsImg && (
                  <div className={styles.instructions_container}>
                    <div className={`px-4 py-3 opacity-50 ${styles["f-12"]}`}>
                      Instructions:-
                      <ul className="mb-0 mt-2">
                        <li className={`opacity-50 ${styles["f-12"]}`}>
                          Use the external ID provided by JigsawML
                        </li>
                        <li className={`opacity-50 ${styles["f-12"]}`}>
                          Create an IAM role with read-only access permissions policy
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.giveAccess_container}>
                {selectedServer === awsImg && (
                  <button
                    className={`${styles.giveAccess_button} ${arnRole && selectedServer && styles.giveAccess_button_highlight}`}
                    onClick={() => giveAccess()}
                  >
                    <img src={correct} alt="correct" />
                    Give Access
                  </button>
                )}
              </div>
            </div>
          )}
          {mode === "Connected" && (
            <div>
              <p className={`mt-4 ${styles.heading_synced}`}>Previously connected cloud accounts</p>
              <div className={`mt-4 ${styles.synced_container}`}>
                <ul>
                  {arnStatusMap && Object.entries(arnStatusMap).length > 0 && Object.entries(arnStatusMap).map(([arn, status], index) => (
                    <li key={index} className={styles.synced_list}>
                      <img className={styles.cloud_platform_image} src={awsImg} alt="aws" />
                      <span className={styles.text}>{arn}</span>
                      <img className={styles.reset_image} src={reset} alt="reset" />
                      <img className={styles.disconnect_image} src={disconnect} alt="disconnect" />
                      {(status === "COMPLETED" || status === "DONE" || status === "SUCCEEDED") && (
                        <img className={styles.status_image} src={completed} alt="completed" />
                      )}
                      {status === "RUNNING" && (
                        <img className={styles.status_image} src={progress} alt="progress" />
                      )}
                      {status === "FAILED" && (
                        <img className={styles.status_image} src={failed} alt="failed" />
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <Toastify
          show={ctx.showToastr.show}
          type={ctx.showToastr.type}
          message={ctx.showToastr.text}
          handleClose={() => {
            ctx.setShowToastr((props) => {
              return { ...props, show: false };
            });
          }}
        />
      </ModalUI>
    </div>
  );
};

export default AddConnectCloudModal;
