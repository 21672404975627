import React, { useState, useCallback , useContext } from "react";
import ModalUI from "../../../../components/UI/ModalUI/ModalUI";
import { Row, Col, Form } from "react-bootstrap";
import styles from "./UploadFileModal.module.css";
import {
  UploadSvg,
  FileSvg,
  CrossSvg,
  PdfIconSvg,
} from "../../../../../images/svg/icons";
import { useDropzone } from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import api from "../../../../../common/api";
import context from '../../../../../store/create-context';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const UploadFileModal = (props) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progressBarCount, setProgressBarCount] = useState(0);
  const [showDoneBtn, setDoneBtn] = useState(false);

  const ctx = useContext(context);
  const [showButtons, setShowButtons] = useState({
    'done':false,
    'upload':false,
  });

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Calculate total size of the new files
      const newFilesSize = acceptedFiles.reduce((total, file) => total + file.size, 0);
      // Calculate total size of all files (existing + new)
      const totalSize = uploadedFiles.reduce((total, file) => total + file.size, 0) + newFilesSize;
      // Check if total size exceeds the limit
      if (totalSize > MAX_FILE_SIZE) {
        alert("Total file size exceeds 10MB. Please upload smaller files.");
        return;
      }
      setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
      setShowButtons({ 'done':false,'upload': true });
    },
    [uploadedFiles]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop, disabled: uploadedFiles.length ? true : false });

  const uploadFile = async (file) => {
    let jml_workspace_id = sessionStorage.getItem("jml_workspace_id");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("jml_workspace_id", jml_workspace_id);
    formData.append("user_input", "File upload");
    formData.append("description_id", jml_workspace_id);
    formData.append("cloud_provider", "AWS");
    formData.append("use_cache", ctx.useCache);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total);
        setProgressBarCount(percentage);
      },
    };

    return await api.post(`/fileUpload`, formData, config);

    // try {
    //   const response = await api.post(`/fileUpload`, formData, config);

    //   if (
    //     response.data &&
    //     response.data.jml_workspace_id === jml_workspace_id
    //   ) {
    //     sessionStorage.setItem("jml_action_id", response.data.jml_action_id);
    //   }
    //   // if(uploadedFiles.length <=1) {setDoneBtn(true)};
    //   // You can set the server response here if needed
    // } catch (error) {
    //   console.error("Error uploading file: ", error);
    // }
  };

  const handleUploadHandler = async () => {
    // uploadedFiles.forEach((file) => {
    //   uploadFile(file);
    // });

    try {
      setShowButtons({ 'done':false, 'upload':false });
      const uploadPromises = uploadedFiles.map(async (file) => {
        return uploadFile(file);
      });
      const response = await Promise.all(uploadPromises);
      if (response) {
        response.forEach(function (res) {
          sessionStorage.setItem("jml_action_id", res.data.jml_action_id);
        });
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      ctx.setShowToastr({
        show: true,
        type: "error",
        text: error?.response?.data?.error,
      });
    } finally {
      setShowButtons({ 'done':true,'upload':false });
      setUploadedFiles([]);
      setProgressBarCount(0);
      props.setCheckActivity(true);
    }
  };

  const removeFile = (file) => {
    const files = [...uploadedFiles];
    files.splice(files.indexOf(file), 1);
    if (files.length) {
      setShowButtons({ 'done':false,'upload':false });
    }
    setUploadedFiles(files);
  };

  const modalCloseHandler = () => {
    props.handleClose();
    setUploadedFiles([]);
    setProgressBarCount(0);
    setShowButtons({'done':false,'upload':false});
  };
  return (
    <ModalUI
      show={props.show}
      handleClose={() => {progressBarCount == 0 && modalCloseHandler()}}
      theme="dark"
      className={styles["bg-color"]}
      classes="border-no pb-0"
      modalTitle="Upload your files"
      size="md"
    >
      <Row>
        <Col>
          <p className={styles["heading"]}>
            Supported formats: <b>pdf</b>, <b>docx</b>, <b>txt</b>, <b>zip</b>,code <b>(.c/cpp/.java/.py/.ru)</b>
          </p>
          <div
            {...getRootProps({
              className: `dropzone ${styles["custom-dropzone"]}`,
            })}
          >
            <input {...getInputProps()} />
            <div className={styles["custom-dropzone-inner"]}>
              <FileSvg />
              <p>click & drop your files here</p>
            </div>
          </div>
          {uploadedFiles.length > 0 && (
            <div className={styles["uploaded-files-wrapper"]}>
              <p className={styles["heading"]}>Uploaded files</p>
              <div className={styles["all-uploaded-files"]}>
                {uploadedFiles.map((uploadedFile, index) => {
                  return (
                    <div className={styles["uploaded-file"]} key={index}>
                      <div className={styles["uploaded-file-des"]}>
                        <PdfIconSvg />
                        <span>{uploadedFile.name}</span>
                      </div>
                      {
                        showButtons['upload'] &&
                          <button
                            className={styles["uploaded-file-cancel"]}
                            onClick={() => {
                              removeFile(uploadedFile);
                            }}
                          >
                            <CrossSvg />
                          </button>
                      }
                    </div>
                  );
                })}
              </div>

              <div className={styles["cust-progress-bar-wrapper"]}>
                <label
                  className={styles["cust-progress-bar-label"]}
                  style={{ left: `${progressBarCount}%` }}
                >
                  {progressBarCount}%
                </label>
                <ProgressBar
                  now={progressBarCount}
                  className={styles["cus-progress-bar"]}
                  label=""
                />
              </div>
            </div>
          )}
          {showButtons['upload'] && (
            <button
              className={styles["cus-btn"]}
              style={{ float: "right" }}
              onClick={handleUploadHandler}
              data-testid='file-upload-btn'
            >
              <UploadSvg />
              <span>Upload</span>
            </button>
          )}
          {showButtons['done'] && (
            <button
              className={styles["cus-btn"]}
              style={{ float: "right" }}
              onClick={modalCloseHandler}
              data-testid='file-done-btn'
            >
              <UploadSvg />
              <span>Done</span>
            </button>
          )}
        </Col>
      </Row>
    </ModalUI>
  );
};

export default UploadFileModal;
